const { API_ENDPOINT } = require(".");

export const uploadClientInfo = async (data: any) => {
  const response = await fetch(`${API_ENDPOINT}/upload-info`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const user = await response.json();
  return user;
};

export const getAllClientInfo = async () => {
  const response = await fetch(`${API_ENDPOINT}/get-client-info`, {
    method: "GET",
    credentials: "include",
  });
  const users = await response.json();
  return users;
};

export const checkPermission = async () => {
  const response = await fetch(`${API_ENDPOINT}/check-permission`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      api_key: localStorage.getItem('api_key'),
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const result = await response.text();
  return { status: response.status, message: result };
};