import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDevelopments } from "../../api/developments";
import { PayloadAction } from '@reduxjs/toolkit';

interface LayoutState {
    headerHeight: number;
    menubarHeight: number;
    [key: string]: number;
}
// INITIAL STATE
const initialState = {
    headerHeight: 0,
    menubarHeight: 0,
};


// SLICE OBJECT
export const layoutsSlice = createSlice({
    name: "layouts",
    initialState,
    reducers: {
        setLayoutValue: (
            state: LayoutState,
            action: PayloadAction<{ name: string; value: number; }>
        ) => {
            state[action.payload.name] = action.payload.value;
        },
    },
});

// SELECTORS
export const selectLayoutHeaderHeight = (state: any) =>
    state.layouts.headerHeight;
export const selectLayoutMenubarHeight = (state: any) =>
    state.layouts.menubarHeight;

// EXPORTS
export const {
    setLayoutValue
} = layoutsSlice.actions;
export default layoutsSlice.reducer;
