import React, { useContext } from "react";
import Info from "./Info";
import Contact from "../../../Contact/Contact";

export default function Popup(props: any) {
  return (
    <div
      className={`${!props.isMap && "absolute top-8 left-24"
        } w-[400px] bg-white  shadow-lg border rounded-lg`}
    >
      {!props.isMap && (
        <p
          className="text-white absolute top-2 right-2 font-bold text-xl hover:cursor-pointer"
          onClick={() => {
            props.setCurrentObj(null);
          }}
        >
          ✕
        </p>
      )}
      <Info obj={props.obj} customiseImg={true} />
      <div className="p-6 border-t border-t-gray-200/70 bg-secondary-light/5">
        <Contact page="onmap" />
      </div>
    </div>
  );
}
