import React, { useState, useContext, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./Header.module.scss";
import { NavLink } from "react-router-dom";
import { SalesContext } from "../../context/SalesContext";
import { setAllDevelopmentStatus } from "../../features/developments/developmentsSlice";
import { IGlobalContext } from "../../types/types";
import { GlobalContext } from "../../context/GlobalContext";
import {
  CalculatorIcon,
  HouseIcon,
  ListIcon,
  MapIcon,
  MenuIcon,
  MortgageIcon,
  TableIcon,
  TubeIcon,
  YieldIcon,
  CloseIcon,
  RentServiceIcon,
  ApartmentIcon,
  ContactIcon,
} from "../../assets/assetExport";
import { NavHashLink } from "react-router-hash-link";
import { setLayoutValue } from "../../features/layouts/layoutsSlice";

const nvreLogoWhite = require("../../assets/media/logo/company/nvreLogoWhite.png");
const nvreLogoPurple = require("../../assets/media/logo/company/nvreLogoPurple.png");

function Header(props: any) {
  // let lastScroll = 0;
  // const [addBackground, setAddBackground] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  // const dispatch = useDispatch();
  const headerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const {
    lang,
    setLang,
    isSmallScreen,
    permission,
    adminOn,
    setShowPermissionPopup,
    setPermission,
  } = useContext(GlobalContext) as IGlobalContext;
  const [expandedHeaders, setExpandedHeaders] = useState<{ [key: string]: boolean; }>({});
  const expandableHeaderRef = useRef<HTMLDivElement>(null);

  const hideMobileHeader = () => {
    setShowMenu(false);
    setExpandedHeaders({});
    if (document.querySelector("html")) {
      document.querySelector("html")!.style.overflow = "visible";
    }
  };

  const toggleMenu = (bool: boolean) => {
    if (document.querySelector("html")) {
      document.querySelector("html")!.style.overflow = bool
        ? "hidden"
        : "visible";
    }
    setShowMenu(bool);
    setExpandedHeaders({});
  };

  // useEffect(() => {
  //   if (props.page !== "tube" && props.page !== "login" && !isSmallScreen) {
  //     let breakpoint = [window.innerHeight - 50, window.innerHeight - 0];
  //     const handleScroll = (event: any) => {
  //       const currentScroll = window.scrollY;
  //       if (currentScroll >= breakpoint[0]) {
  //         if (currentScroll > breakpoint[1]) {
  //           // SCROLL DOWN
  //           if (currentScroll > lastScroll) {
  //             setShowMenuDesktop(false);
  //             setAddBackground(false);
  //           }
  //           // SCROLL UP
  //           else if (currentScroll < lastScroll) {
  //             setShowMenuDesktop(true);
  //             setAddBackground(true);
  //           }
  //         }
  //       } else {
  //         setShowMenuDesktop(true);
  //         setAddBackground(false);
  //       }
  //       lastScroll = currentScroll;
  //     };
  //     window.addEventListener("scroll", handleScroll);
  //     return () => {
  //       window.removeEventListener("scroll", handleScroll);
  //     };
  //   }
  // }, [props.page]);

  function ExpandableHeader({
    title,
    to,
    list,
    desc,
    headerKey,
    expand,
    setExpand,
  }: {
    title: any;
    to: string;
    list: any;
    desc: any;
    headerKey: string;
    expand: any;
    setExpand: any;
  }) {
    // const isExpanded = expandedHeaders[headerKey] || false;
    return (
      // <div className={`relative hover:cursor-pointer px-6 ${styles.hoverHeader}`}>
      <div
        ref={expandableHeaderRef}
        className={`relative  ${isSmallScreen ? "" : "px-6"} ${styles.expandableHeader}`}
      >
        {/* HEADER */}
        <p className="hover:cursor-default"
          onClick={() => { setExpand({ [headerKey]: true }); }}
        >{lang ? title.en : title.zh}</p>

        {/* PLACEHOLDERS FOR SPACE */}
        {desc.map((_: any, index: number) => {
          return (
            <div
              className={`${styles.expandedContainer} flex-col gap-6 pt-[6.5rem] ${isSmallScreen ? (expand ? "h-auto flex" : "hidden") : ""}`}
              key={"placeholder-container" + index}
            >
              {list[index].map((i: any, index1: number) => {
                return <p key={`placeholder-${index}-${index1}`}>&nbsp;</p>;
              })}
            </div>
          );
        })}

        {/* EXPANDED HEADERS */}
        <div className={`absolute left-0 ${isSmallScreen ? "top-[3rem] px-4" : "top-[4rem] px-6"} h-auto ${styles.expandedContainer}
        ${isSmallScreen ? (expand ? "h-auto flex flex-col" : "hidden") : ""}`}>
          {
            desc.map((_: any, index: number) => {
              return (<>
                <div
                  className={`${index > 0 ? "mt-6" : ""} font-medium bg-white whitespace-nowrap flex flex-col gap-4`}
                  key={"header" + index}
                >
                  <p className="text-gray-400 font-normal text-[12px] font-sans">
                    {desc[index]}
                  </p>
                  {list[index].map((i: any, index1: number) => {
                    return (
                      <NavLink
                        to={i.to}
                        onClick={(e) => {
                          hideMobileHeader();
                        }}
                        className={"flex gap-3 items-center hover:cursor-pointer"}
                        key={`expandableheader-${index}-${index1}`}
                      >
                        <div className="bg-tertiary-light rounded-xl p-2">{i.icon}</div>
                        <p>{lang ? i.en : i.zh}</p>
                      </NavLink>
                    );
                  })}
                </div>
              </>);
            })
          }
        </div>
      </div >
    );
  }

  useEffect(() => {
    let resizeTimer: ReturnType<typeof setTimeout>;

    const handleResize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        if (headerRef.current && headerRef.current.offsetHeight > 0) {
          dispatch(setLayoutValue({ name: "headerHeight", value: window.innerWidth < 650 ? 81.5 : headerRef.current.offsetHeight }));
        }
      }, 300);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      if (resizeTimer) clearTimeout(resizeTimer);
    };

  }, [headerRef.current]);

  return (
    <>


      <header
        className={`${styles.headerContainer
          } fixed flex flex-col items-stretch left-0  w-screen z-[9999] bg-white overflow-hidden font-medium top-0
           ${isSmallScreen ? "p-5 pt-0" : "px-10 py-5 pt-0"}
           ${isSmallScreen ? (showMenu ? "h-screen" : "bg-white") : ""} `}
        //  ${isSmallScreen ? (showMenu ? "h-screen" : "bg-white bg-opacity-50 backdrop-blur-md") : ""} `}
        // onMouseOut={isSmallScreen ? undefined : hideMobileHeader}
        ref={headerRef}
      >
        <div className={`bg-tertiary-light -ml-10 -mr-10 mb-5 px-5 py-1 ${styles.top}`}>
          <p className={`${styles.scroll}  text-secondary-dark text-sm font-light text-center`}>
            <NavLink className="text-sm hover:underline" to="/property?view=list">NVRE is here to help you find the ideal property <ApartmentIcon width="12" height="12" className="inline -mt-1" /> that meets your requirements.</NavLink>&nbsp;&nbsp;<NavHashLink className="text-sm font-normal hover:underline" to="/#contact-us" >Need personalized assistance? <ContactIcon width="12" height="12" className="inline -mt-1" /> Reach out to us.</NavHashLink>
          </p>
        </div>
        <div className="flex flex-col justify-between">
          <nav
            className={`${props.darkBackground ? "dark-background" : "light-background"
              } flex ${isSmallScreen
                ? "flex-col items-start"
                : "flex-row justify-between items-start"
              } w-full   `}
          >
            <div className="flex justify-between items-start w-full">
              <NavLink
                className={`hover:cursor-pointer
          ${isSmallScreen ? "" : "h-full"}`}
                to="/"
              >
                <div className={`flex gap-4 items-end`}>
                  <img
                    className={`${isSmallScreen ? "h-5" : "h-8"} object-contain`}
                    src={props.darkBackground ? nvreLogoWhite : nvreLogoPurple}
                    alt="nvre-logo"
                  />
                  <p className="font-semibold leading-3 text-secondary-light/50">
                    LONDON
                  </p>
                </div>
              </NavLink>
              <div className="hover:cursor-pointer">
                {isSmallScreen &&
                  (!showMenu ? (
                    <div onClick={() => toggleMenu(true)}>
                      <MenuIcon height="20" width="20" />
                    </div>
                  ) : (
                    <div onClick={() => toggleMenu(false)}>
                      <CloseIcon height="20" width="20" />
                    </div>
                  ))}
              </div>
            </div>

            {(!isSmallScreen || (isSmallScreen && showMenu)) && (
              <>
                {/* HEADER ITEMS */}
                <div
                  className={`relative flex text-nowrap  text-gray-700 
            ${isSmallScreen
                      ? "flex-col mt-10 gap-5 items-start ml-5"
                      : "flex-row items-start"
                    }
            `}
                >
                  <ExpandableHeader
                    title={{ en: "New Homes", zh: "买房" }}
                    to="/property"
                    desc={lang ? ["BROWSE DEVELOPMENTS"] : ["浏览伦敦房产"]}
                    headerKey="forSale"
                    expand={expandedHeaders["forSale"]}
                    setExpand={setExpandedHeaders}
                    list={[[
                      {
                        to: "/property?view=list",
                        en: "List View",
                        zh: "列表",
                        icon: <ListIcon width="18" height="18" />,
                      },
                      ...(permission > 4 ? [{
                        to: "/property?view=table",
                        en: "Table View",
                        zh: "表格",
                        icon: <TableIcon width="18" height="18" />,
                      }] : []),
                      {
                        to: "/property?view=tube",
                        en: "Tube View",
                        zh: "地铁图",
                        icon: <TubeIcon width="18" height="18" />,
                      },
                      {
                        to: "/property?view=map",
                        en: "Map View",
                        zh: "地图",
                        icon: <MapIcon width="18" height="18" />,
                      },
                    ]]}
                  />
                  <ExpandableHeader
                    title={{ en: "Landlords", zh: "房东" }}
                    to=""
                    desc={lang ? ["LANDLORD TOOLS", "LANDLORD SERVICES"] : ["房东实用工具", "房东服务"]}
                    headerKey="landlords"
                    expand={expandedHeaders["landlords"]}
                    setExpand={setExpandedHeaders}
                    list={[[
                      {
                        to: "/let",
                        en: "Let",
                        zh: "房东租赁",
                        icon: <RentServiceIcon width="18" height="18" />,
                      }
                    ], [
                      {
                        to: "/services/stamp-duty-calculator",
                        en: "Stamp Duty Calculator",
                        zh: "印花税计算器",
                        icon: <CalculatorIcon width="18" height="18" />,
                      },
                      {
                        to: "/services/rental-yield",
                        en: "Rental Yield",
                        zh: "租金收益率",
                        icon: <YieldIcon width="18" height="18" />,
                      },
                      {
                        to: "/services/rent-estimate",
                        en: "Rent Estimator",
                        zh: "租金估算器",
                        icon: <HouseIcon width="18" height="18" />,
                      },
                      {
                        to: "/services/mortgage-calculator",
                        en: "Mortgage Calculator",
                        zh: "贷款计算器",
                        icon: <MortgageIcon width="18" height="18" />,
                      },
                    ]]}
                  />
                  <NavLink
                    className={`${isSmallScreen ? "" : "px-6"} hover:text-main`}
                    to="/about"
                    onClick={() => {
                      hideMobileHeader();
                    }}
                  >
                    <p>{lang ? "About Us" : "关于我们"}</p>
                  </NavLink>
                  <p
                    className={`${isSmallScreen ? "" : "pl-6"
                      } hover:text-main nav-lang hover:cursor-pointer`}
                    onClick={() => {
                      setLang(!lang);
                      hideMobileHeader();
                    }}
                  >
                    {lang ? "中文" : "English"}
                  </p>
                  {/* 
          1. admin=on, permission <= 4    Authenticate
          2. admin=on, permission > 4     Client Info
          3. permission <= 4              null
          4. permission > 4               Client Info
          
          */}
                  {permission <= 4 ? (
                    adminOn && (
                      <p
                        className={"hover:cursor-pointer border-l pl-5 ml-5"}
                        onClick={() => {
                          setShowPermissionPopup(true);
                        }}
                      >
                        Authenticate
                      </p>
                    )
                  ) : (
                    <>
                      <NavLink to="/client-info" className={`${isSmallScreen ? "border-t pt-5" : "border-l pl-5 ml-5"} `}>
                        Client Info
                      </NavLink>
                      <p
                        className={`${isSmallScreen ? "" : "ml-6 "} border border-main/30 rounded-sm -mt-[0.1rem] px-2 py-0 text-main/30 hover:cursor-pointer`}
                        onClick={() => {
                          // document.cookie = "api_key=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                          localStorage.setItem("api_key", "");

                          let url = new URL(window.location.href);
                          let searchParams = url.searchParams;

                          if (searchParams.get("view") === "table") {
                            searchParams.set("view", "list");
                          }

                          if (searchParams.get("admin") === "on") {
                            searchParams.delete("admin");
                          }

                          window.location.href = url.toString();
                          setPermission(0);
                        }}
                      >
                        Client Mode
                      </p>
                    </>
                  )}
                </div>

                {/* Slogen */}
                <div
                  className={`${!isSmallScreen && styles.slogen} absolute left-10  ${isSmallScreen ? "bottom-40" : "hidden bottom-5"
                    } ${lang ? "font-poster" : "font-poster-cn"} text-4xl leading-normal text-gray-300/80`}
                >
                  {lang ? <>
                    <p>Elegant Living,</p>
                    <p>Confident Investing</p>
                  </>
                    :
                    <p>优雅生活，自信投资</p>}
                </div>
              </>
            )}
          </nav>
        </div>

      </header>
    </>

  );
}

export default Header;
