import { useContext, useEffect, useState } from "react";
import { sendEmail } from "../../api/email";
import { GlobalContext } from "../../context/GlobalContext";
import { IGlobalContext } from "../../types/types";
import styles from "./Contact.module.scss";
import { AddressIcon, EmailIcon, PhoneIcon } from "../../assets/assetExport";
const qrFollowUs = require("../../assets/media/logo/qrCode/nvre-wechat-subscribe-qrcode.png");
const qrChatToUs = require("../../assets/media/logo/qrCode/nvre-wechat-qrcode.png");
const logo = require("../../assets/media/logo/company/nvreLogoPurple.png");

export default function Contact(props: any) {
    const { lang } = useContext(GlobalContext) as IGlobalContext;

    const [name, setName] = useState('');
    const [to, setTo] = useState<string>('');
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const [disableButton, setDisableButton] = useState(false);

    const [response, setResponse] = useState('');


    useEffect(() => {
        setSubject(lang ? "Buying and Selling" : "买房卖房");
    });

    const submitForm = async (e: any) => {
        e.preventDefault();
        setDisableButton(true);
        const response = await sendEmail(name, to, subject, content);
        console.log(response);
        if (response) {
            setResponse("Email sent successfully");
            setTo("");
            setName("");
            setSubject(lang ? "Buying and Selling" : "买房卖房");
            setContent("");
        } else {
            setResponse("Email failed");
        }
        setDisableButton(false);
    };

    return (
        <div className="" id="contact-us">
            {props.page !== "onmap" &&
                <div className="relative">
                    <h2 className="text-secondary-dark">{lang ? "Contact Us" : "联系我们"}</h2>
                    <p className={`${lang ? "font-poster" : "font-poster-cn"} desc`}>{lang ? "Chat with us" : "为您解答疑虑"}</p>
                </div>
            }
            {/* <div className="flex gap-10 w-full"> */}
            <div className={`flex  ${props.page === "onmap" ? "flex-col" : "flex-col md:flex-row"} gap-10 justify-between items-start`}>
                <ul className={` flex ${props.page === "onmap" ? "gap-6 w-full" : "mt-10 flex-col"} justify-between`}>
                    <div>
                        <li className="flex gap-3 items-center ">
                            <EmailIcon width="18" height="18" />
                            {props.page !== "onmap" && <span className="text-secondary-dark/80 font-bold">{lang ? "EMAIL: " : "邮箱："}</span>}
                            <a href="mailto:contact@nvre.co.uk" className={`text-lg ${props.page === "onmap" && "text-secondary-dark/70 font-medium"}`}>contact@nvre.co.uk</a>
                        </li>
                        <li className={`flex gap-3 items-center ${props.page === "onmap" ? "mt-2" : "mt-3"} mb-4`}>
                            <PhoneIcon width="18" height="18" />
                            {props.page !== "onmap" && <span className="text-secondary-dark/80 font-bold">{lang ? "OFFICE: " : "电话号码："}</span>}
                            <span className={`text-nowrap ${props.page === "onmap" && "text-secondary-dark/70 font-medium"}`}>+44 (0)20 3907 4024</span>
                        </li>
                        {props.page === "onmap" &&
                            <li>
                                <img className="w-20 opacity-5" src={logo} alt="logo" />
                            </li>
                        }
                    </div>
                    {props.page !== "onmap" &&
                        <li className="flex gap-3 items-start">
                            <AddressIcon width="18" height="18" />
                            <div className="flex flex-col gap-1 -mt-1 items-start text-lg">
                                <span>16 Berkeley Street, Mayfair</span>
                                <span>London</span>
                                <span>W1J 8DZ</span>
                                <span>United Kingdom</span>
                            </div>
                        </li>
                    }
                    <li className={`${props.page !== "onmap" && "mt-10"} flex gap-2 self-start md:self-end`}>
                        <div className="">
                            <img className={`${props.page === "onmap" ? "w-24" : "w-24"}`} src={qrFollowUs} alt="WechatID for follow us: " />
                            <p className="text-center text-main/30 text-xs font-bold">{lang ? "Follow Us" : "关注我们"}</p>
                        </div>
                        <div className="">
                            <img className={`${props.page === "onmap" ? "w-24" : "w-24"}`} src={qrChatToUs} alt="WechatID for chat to us: " />
                            <p className="text-center text-main/30 text-xs font-bold">{lang ? "Chat to Us" : "一对一咨询"}</p>
                        </div>
                    </li>
                </ul>
                {props.page !== "onmap" &&
                    <form className={`${styles.popupForm} mt-20 md:mt-4 flex flex-col  w-full ${props.page === "popup" ? "gap-2 w-[300px]" : "gap-6 md:min-w-[300px] lg:min-w-[500px] max-w-[700px]"}`} onSubmit={submitForm}>
                        <div className={styles.formStyle}>
                            <label>
                                <span >{lang ? "Full Name" : "姓名"}</span>
                                <input className="txt"
                                    type="text"
                                    placeholder={lang ? "Your name" : "您的姓名"}
                                    value={name}
                                    onChange={(e) => { setName(e.target.value); }} />
                            </label>
                        </div>
                        <div className={styles.formStyle}>
                            <label>
                                <span>{lang ? "Email" : "邮箱"}</span>
                                <input className="txt"
                                    type="email"
                                    placeholder="your@email.com"
                                    value={to}
                                    onChange={(e) => { setTo(e.target.value); }} />
                            </label>
                        </div>
                        <div className={styles.formStyle}>
                            <label >
                                <span>{lang ? "Subject" : "主题"}</span>
                                <select className="txt" value={subject} onChange={(e) => { setSubject(e.target.value); }}>
                                    <option>{lang ? "Buying and Selling" : "买房卖房"}</option>
                                    <option>{lang ? "Letting and Renting" : "租赁"}</option>
                                    <option>{lang ? "General Enquiries" : "其他"}</option>
                                </select>
                            </label>
                        </div>
                        <div className={styles.formStyle}>
                            <label>
                                <span>{lang ? "Message" : "留言"}</span>
                                <textarea className="txt" placeholder="" value={content} onChange={(e) => { setContent(e.target.value); }}></textarea>
                            </label>
                        </div>
                        <button className={`btn bg-main hover:bg-secondary-dark text-white self-end  ${props.page === "popup" ? "mt-3 px-3" : "mt-10 px-6"}`} type="submit" disabled={disableButton}>{lang ? "SUBMIT" : "提交"}</button>
                        <p>{response}</p>
                    </form >
                }

            </div>

        </div >
    );
}

