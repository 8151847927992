import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import styles from "./Filter.module.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllDevelopments,
  clearFilter,
  setFilter,
  getFilteredDevelopmentIds,
  getFilters,
  type Filter,
  setFilters,
  // getCurrentPriceRange,
  initialFilter,
  fetchPostcodeFilteredDevelopments,
  getIsPriceManuallySet,
  setPriceManuallySet,
} from "../../../features/developments/developmentsSlice";
import { SalesContext } from "../../../context/SalesContext";
import { IGlobalContext, ISalesContext } from "../../../types/types";
import { GlobalContext } from "../../../context/GlobalContext";
import {
  LeftIcon,
  RightArrowIcon,
  RightIcon,
} from "../../../assets/assetExport";
import { selectLayoutHeaderHeight, selectLayoutMenubarHeight } from "../../../features/layouts/layoutsSlice";

export default function Filter(props: any) {
  const dispatch = useDispatch();
  const filterRef = useRef<HTMLDivElement>(null);

  const {
    showFilter,
    setShowFilter,
  } = useContext(SalesContext) as ISalesContext;
  const { lang, isRealMobile, filterPriceChanged,
    setFilterPriceChanged } = useContext(GlobalContext) as IGlobalContext;

  const headerHeight = useSelector(selectLayoutHeaderHeight);
  const menubarHeight = useSelector(selectLayoutMenubarHeight);
  const filters = useSelector(getFilters);
  const [localFilters, setLocalFilters] = useState<Filter>(filters);
  const [error, setError] = useState("");

  const allDevelopments = useSelector(getAllDevelopments);
  const filteredDevelopments = useSelector(getFilteredDevelopmentIds);
  // const currentPriceRange = useSelector(getCurrentPriceRange);
  const displayPriceRange = localFilters.price;
  // const displayPriceRange = filterPriceChanged ? localFilters.price : currentPriceRange;
  const isPriceManuallySet = useSelector(getIsPriceManuallySet);

  const handleUpdateFilter = useCallback(() => {
    if (!allDevelopments) return;
    if (localFilters.useRadius) {
      try {
        const { postcode, radius } = localFilters;
        dispatch(fetchPostcodeFilteredDevelopments({ postcode, radius }) as any).unwrap();
      } catch (error) {
        console.error("Error fetching postcode filtered developments:", error);
        setError("Failed to fetch developments based on postcode and radius. Please try again.");
      }
    }

    const filtersToApply = localFilters;
    // const filtersToApply = filterPriceChanged ? localFilters : {
    //   ...localFilters,
    //   price: currentPriceRange
    // };
    dispatch(setFilters(filtersToApply));
  }, [localFilters, dispatch]);

  // useEffect(() => {
  //   if (!isPriceManuallySet) {
  //     setLocalFilters((prevFilters: any) => ({
  //       ...prevFilters,
  //       price: currentPriceRange
  //     }));
  //   }
  // }, [currentPriceRange, isPriceManuallySet]);


  // ========== SET FILTERS ========== //
  function updateFilterPrice(e: any, index: number) {
    setFilterPriceChanged(true);

    const value = Number(
      e.target.value.trim().replace("£", "").replace(/,/g, "")
    );

    setLocalFilters((prevFilters: Filter) => ({
      ...prevFilters,
      price: prevFilters.price.map((p, i) => i === index ? value : p) as [number, number]
    }));

    dispatch(setPriceManuallySet(true));

    // setLocalFilters((prevFilters: Filter) => {
    //   const newPrice = [...(prevFilters.price || [0, 0])];
    //   newPrice[index] = value;
    //   return {
    //     ...prevFilters,
    //     price: newPrice
    //   };
    // });


    // if (localFilters.price && localFilters.price.length > 0) {
    //   if (index === 1) {
    //     // the right one
    //     setLocalFilters((prevFilters: any) => ({
    //       ...prevFilters,
    //       price: [prevFilters.price[0], value]
    //     }));
    //   } else if (index === 0) {
    //     // the left one
    //     setLocalFilters((prevFilters: any) => ({
    //       ...prevFilters,
    //       price: [value, prevFilters.price[1]]
    //     }));
    //   }
    // }
  }

  function updateFilterZone(e: React.ChangeEvent<HTMLInputElement>) {
    setLocalFilters((prevFilters: Filter) => ({
      ...prevFilters,
      zone: {
        ...prevFilters.zone,
        [e.target.name]: e.target.checked
      }
    }));
  }

  function updateFilterPostcode(e: React.ChangeEvent<HTMLInputElement>) {
    setLocalFilters((prevFilters: Filter) => ({
      ...prevFilters,
      postcode: e.target.value.toUpperCase()
    }));
  }

  const updateFilterRadius = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value.trim(), 10);
    const parsedValue = isNaN(value) || value < 1 ? 1 : value;
    setLocalFilters((prevFilters: Filter) => ({
      ...prevFilters,
      radius: parsedValue
    }));
  }, []);

  function updateFilterUseRadius(e: React.ChangeEvent<HTMLInputElement>) {
    setLocalFilters((prevFilters: Filter) => ({
      ...prevFilters,
      useRadius: e.target.checked
    }));
  }

  function updateFilterBorough(e: React.ChangeEvent<HTMLInputElement>) {
    setLocalFilters((prevFilters: Filter) => ({
      ...prevFilters,
      borough: e.target.value.toUpperCase().trim()
    }));
  }

  function updateFilterBedrooms(e: React.ChangeEvent<HTMLInputElement>) {
    setLocalFilters((prevFilters: Filter) => ({
      ...prevFilters,
      bedrooms: {
        ...prevFilters.bedrooms,
        [e.target.name]: e.target.checked
      }
    }));
  }

  function updateFilterDeveloper(e: React.ChangeEvent<HTMLInputElement>) {
    setLocalFilters((prevFilters: Filter) => ({
      ...prevFilters,
      developer: {
        ...prevFilters.developer,
        [e.target.name]: e.target.checked
      }
    }));
  }

  function updateFilterOtherDeveloper(e: React.ChangeEvent<HTMLInputElement>) {
    setLocalFilters((prevFilters: Filter) => ({
      ...prevFilters,
      ["other-developer"]: e.target.value.toUpperCase().trim()
    }));
  }

  function updateFilterCompletion(e: React.ChangeEvent<HTMLInputElement>) {
    setLocalFilters((prevFilters: Filter) => ({
      ...prevFilters,
      completion: {
        ...prevFilters.completion,
        [e.target.name]: e.target.checked
      }
    }));
  }

  function updateFilterHouse(e: React.ChangeEvent<HTMLInputElement>) {
    setLocalFilters((prevFilters: Filter) => ({
      ...prevFilters,
      house: e.target.checked
    }));
  }

  function handleReset() {
    dispatch(clearFilter());
    setLocalFilters(initialFilter);
    setFilterPriceChanged(false);
  }


  function adjustFilterTopValue() {
    if (isRealMobile) return;
    const filter = filterRef.current;
    const content = props.viewRef.current;
    if (!filter || !content) return;

    let contentSpecs = content.getBoundingClientRect();
    let viewportHeight = window.innerHeight;
    let contentBottomPosition = contentSpecs.bottom;


    if (contentBottomPosition < viewportHeight) {
      let difference = viewportHeight - contentBottomPosition;
      filter.style.top = `${menubarHeight + headerHeight - difference}px`;
    } else {
      filter.style.top = `${menubarHeight + headerHeight}px`;
    }
  }

  // const handleAutoMinMax = () => {
  //   dispatch(setFilter({ filter: 'price', value: currentPriceRange }));
  //   dispatch(setPriceManuallySet(false));
  //   setLocalFilters((prevFilters: any) => ({
  //     ...prevFilters,
  //     price: currentPriceRange
  //   }));
  // };

  useEffect(() => {
    handleReset();
    adjustFilterTopValue();

    const handleScroll = () => {
      adjustFilterTopValue();
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    adjustFilterTopValue();
  }, [headerHeight, menubarHeight, showFilter]);

  function ActionButtons() {
    return (
      <div className={`${styles.actionButtons} grid gap-2 items-center`}>
        <button
          className={`${styles.btnOutline} ${styles.thicker} btn-outline btn font-normal whitespace-nowrap text-secondary-dark bg-transparent`}
          onClick={handleUpdateFilter}
        >
          {lang ? "UPDATE FILTER" : "更新筛选"}
        </button>
        <button
          className={`font-normal text-sm text-secondary-dark hover:underline p-4`}
          onClick={handleReset}
        >
          {lang ? "Reset" : "重置"}
        </button>
      </div>
    );
  }


  return (
    <div
      ref={filterRef}
      className={`fixed right-0 z-[99]`}
      style={{
        height: "calc(100vh - " + (headerHeight + menubarHeight) + "px)",
        maxHeight: "calc(100vh - " + (headerHeight + menubarHeight) + "px)",
      }}
    >
      <div
        className={`${styles.filterContainer} bg-white ${showFilter ? (isRealMobile ? "w-screen" : "w-[400px]") : "w-0"
          } h-full p-10 pb-20 border-l overflow-y-scroll overflow-x-visible flex flex-col ${!showFilter && "translate-x-full"
          }`}
      >
        {/* HEADER, UPDATE, RESET, EDIT */}
        <div className="">
          <ActionButtons />
        </div>

        {/* PRICE FILTER */}
        <div className="mb-16">
          <p
            className={`${styles.filterHeader} ${styles.special} flex justify-between`}
          >
            <span>
              {lang ? "PRICE" : "价格"}&nbsp;
              <span className="text-gray-400 font-normal">
                {filterPriceChanged && "(manually set)"}
              </span>
            </span>
            {/* <span
              className="font-normal text-gray-400 underline hover:cursor-pointer"
              onClick={handleAutoMinMax}
            >
              auto Min and Max
            </span> */}
          </p>
          <div className="flex gap-2 items-center  ">
            <label className={styles.inputWithCornerLabel}>
              <span>{lang ? "From" : "从"}</span>
              <input
                className="input input-bordered w-full max-w-xs"
                id="outlined-price-from"
                type="text"
                value={
                  displayPriceRange && displayPriceRange[0] ? "£" + displayPriceRange[0].toLocaleString() : 0
                }
                onChange={(e) => {
                  updateFilterPrice(e, 0);
                }}
                placeholder={lang ? "From" : "从"}
              />
            </label>

            <RightArrowIcon width="50" height="50" />
            <label className={styles.inputWithCornerLabel}>
              <span>{lang ? "To" : "至"}</span>
              <input
                className="input input-bordered w-full max-w-xs"
                id="outlined-price-to"
                type="text"
                value={
                  displayPriceRange && displayPriceRange[1] ? "£" + displayPriceRange[1].toLocaleString() : 0
                }
                onChange={(e) => {
                  updateFilterPrice(e, 1);
                }}
                placeholder={lang ? "To" : "至"}
              />
            </label>
          </div>
        </div>

        {/* LOCATION FILTER */}
        <div className="mb-16 text-gray-700">
          <p className={`${styles.filterHeader} `}>
            {lang ? "LOCATION" : "地理位置"}
          </p>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 mb-4">
              <p className="min-w-20">{lang ? "Zone" : "地铁区"}</p>
              {localFilters.zone && Object.keys(localFilters.zone).length > 0 && (
                <div className={`${styles.checkboxesContainer} `}>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.zone["zone-one"]}
                      name="zone-one"
                      onChange={updateFilterZone}
                    />
                    <span>{lang ? "Zone 1" : "1区"}</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.zone["zone-two"]}
                      name="zone-two"
                      onChange={updateFilterZone}
                    />
                    <span>{lang ? "Zone 2" : "2区"}</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.zone["zone-three"]}
                      name="zone-three"
                      onChange={updateFilterZone}
                    />
                    <span>{lang ? "Zone 3" : "3区"}</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.zone["zone-four"]}
                      name="zone-four"
                      onChange={updateFilterZone}
                    />
                    <span>{lang ? "Zone 4" : "4区"}</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.zone["zone-five"]}
                      name="zone-five"
                      onChange={updateFilterZone}
                    />
                    <span>{lang ? "Zone 5" : "5区"}</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.zone["zone-six"]}
                      name="zone-six"
                      onChange={updateFilterZone}
                    />
                    <span>{lang ? "Zone 6+" : "6区"}</span>
                  </label>
                </div>
              )}
            </div>
            <label className="flex gap-2">
              <span className="min-w-20">{lang ? "Postcode" : "邮编"}</span>
              <div className="flex flex-col items-center">
                <input
                  className="input input-sm input-bordered w-full max-w-xs bg-white"
                  type="text"
                  placeholder="W1J 8DZ"
                  value={localFilters.postcode}
                  onChange={updateFilterPostcode}
                />
                <div className={`flex gap-2 mt-2 mb-4 items-center ${!localFilters.useRadius && "opacity-40"}`}>
                  <span className="text-nowrap">Use radius</span>
                  <input
                    className={`toggle ${!localFilters.house
                      ? "bg-gray-300 border-gray-300"
                      : "bg-secondary-dark border-secondary-dark"
                      } `}
                    type="checkbox"
                    checked={localFilters.useRadius}
                    onChange={updateFilterUseRadius}
                  />
                  <span className={`text-nowrap border-l pl-2`}>within</span>
                  <input
                    className={`input input-sm input-bordered w-[50px] max-w-xs bg-white`}
                    type="number"
                    min={1}
                    placeholder="within 1km"
                    value={localFilters.radius}
                    onChange={updateFilterRadius}
                    disabled={!localFilters.useRadius}
                  />
                  <span className={`text-nowrap`}>km</span>
                </div>

              </div>

            </label>
            <label className="flex gap-2">
              <span className="min-w-20">{lang ? "Borough" : "区域"}</span>
              <input
                className="input input-sm input-bordered w-full max-w-xs bg-white"
                type="text"
                placeholder="Camden"
                value={localFilters.borough}
                onChange={updateFilterBorough}
              />
            </label>
          </div>
        </div>

        {/* PROPERTY FILTER */}
        <div className="mb-12 text-gray-700">
          <p className={`${styles.filterHeader} `}>
            {lang ? "PROPERTY" : "房产信息"}
          </p>
          <div className="flex flex-col gap-8">
            <div className="flex gap-2">
              <p className="min-w-32">{lang ? "Bedrooms" : "户型"}</p>
              {localFilters.bedrooms && Object.keys(localFilters.bedrooms).length > 0 && (
                <div className={`${styles.checkboxesContainer} `}>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.bedrooms["bed0"]}
                      name="bed0"
                      onChange={updateFilterBedrooms}
                    />
                    <span>{lang ? "Studio" : "开间"}</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.bedrooms["bed1"]}
                      name="bed1"
                      onChange={updateFilterBedrooms}
                    />
                    <span>{lang ? "1 bed" : "一室"}</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.bedrooms["bed2"]}
                      name="bed2"
                      onChange={updateFilterBedrooms}
                    />
                    <span>{lang ? "2 bed" : "两室"}</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.bedrooms["bed3"]}
                      name="bed3"
                      onChange={updateFilterBedrooms}
                    />
                    <span>{lang ? "3 bed" : "三室"}</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.bedrooms["bed4"]}
                      name="bed4"
                      onChange={updateFilterBedrooms}
                    />
                    <span>{lang ? "4 bed" : "四室"}</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.bedrooms["bed5"]}
                      name="bed5"
                      onChange={updateFilterBedrooms}
                    />
                    <span className="text-nowrap">
                      {lang ? "5 bed+" : "五室"}
                    </span>
                  </label>
                </div>
              )}
            </div>
            <label className="flex gap-2 ">
              <span className="min-w-32">
                {lang ? "Houses Only" : "只显示别墅"}
              </span>
              <input
                className={`toggle ${!localFilters.house
                  ? "bg-gray-300 border-gray-300"
                  : "bg-secondary-dark border-secondary-dark"
                  } `}
                type="checkbox"
                checked={localFilters.house}
                onChange={updateFilterHouse}
              />
            </label>
            <div className="flex gap-2">
              <p className="min-w-32">
                {lang ? "Est. Completion" : "预计交房时间"}
              </p>
              {localFilters.completion && Object.keys(localFilters.completion).length > 0 && (
                <div
                  className={`${styles.checkboxesContainer} ${styles.twoGrid} `}
                >
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.completion["Completed"]}
                      name="Completed"
                      onChange={updateFilterCompletion}
                    />
                    <span>{lang ? "Completed" : "现房"}</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.completion["yr2024"]}
                      name="yr2024"
                      onChange={updateFilterCompletion}
                    />
                    <span>2024</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.completion["yr2025"]}
                      name="yr2025"
                      onChange={updateFilterCompletion}
                    />
                    <span>2025</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.completion["yr2026"]}
                      name="yr2026"
                      onChange={updateFilterCompletion}
                    />
                    <span>2026</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.completion["yr2027"]}
                      name="yr2027"
                      onChange={updateFilterCompletion}
                    />
                    <span>2027+</span>
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* FOOTER, UPDATE, RESET */}
        <div className="border-t pt-6 mt-12">
          <ActionButtons />
        </div>
      </div>

      {/* TOGGLE ARROWS */}
      <div
        onClick={() => {
          setShowFilter(!showFilter);
        }}
        className={`absolute top-[40%] ${showFilter
          ? "right-full translate-x-[50%] "
          : "right-0 translate-x-0 "
          } bg-secondary-light/50 z-[999999] px-1 py-3 rounded-sm hover:cursor-pointer`}
      >
        {showFilter ? (
          <RightIcon width="20" height="20" />
        ) : (
          <LeftIcon width="20" height="20" />
        )}
      </div>
    </div>
  );
}
