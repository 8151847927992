import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./ListBlock.scss";

import { SalesContext } from "../../../../context/SalesContext";
import { IGlobalContext, ISalesContext } from "../../../../types/types";
import Info from "../Common/Info";
import { removeDevelopment } from "../../../../features/developments/developmentsSlice";
import { GlobalContext } from "../../../../context/GlobalContext";

export default function ListBlock(props: any) {
  const dispatch = useDispatch();
  const { editMode } = useContext(SalesContext) as ISalesContext;
  const { setShowContactPopup } = useContext(GlobalContext) as IGlobalContext;

  return (
    <div className="development_small_container  text-gray-700 hover:cursor-pointer"
      onClick={() => {

        setShowContactPopup(true);
      }}>
      <div className="grid_view_development" id={props.obj["id"]}>
        {/* <div className="grid_view_development shadow-md hover:shadow-3xl" id={props.obj["unique-id"]}> */}
        <div className="flex-container with_img">
          {/* <div className="img-container" style={imgStyle(props.obj)}></div> */}
          {editMode &&
            <button
              id="btn-list-remove"
              className="absolute top-[-10px] right-[-10px] text-lg bg-secondary-light p-5 rounded-full w-5 h-5 flex items-center justify-center text-white shadow-md"
              onClick={(e) => {
                console.log("removing development", props.obj.id);
                e.stopPropagation();
                dispatch(removeDevelopment(+props.obj.id));
              }}>
              ✕
            </button>
          }
          <Info obj={props.obj} />
        </div>
      </div>
    </div>
  );
}
