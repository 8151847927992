import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import filtersReducer from "../features/developments/filteredDevelopmentsSlice";
import developmentsReducer from "../features/developments/developmentsSlice";
import layoutsReducer from "../features/layouts/layoutsSlice";
import statesReducer from "../features/states/statesSlice";
import mapzoomReducer from "../features/mapzoom/mapzoomSlice";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// const store = configureStore({
//     reducer: {
//         developments: developmentsReducer,
//         filters: filtersReducer,
//         mapzoom: mapzoomReducer,
//     }
// });

const persistConfig: any = {
    key: 'root',
    storage,
    blacklist: ['states, developments'] // Add this line to exclude the states slice from persistence
};

const rootReducer = combineReducers({
    developments: developmentsReducer,
    mapzoom: mapzoomReducer,
    layouts: layoutsReducer,
    states: statesReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST'], // ignore 'persist/PERSIST' action
            },
        }),
});

let persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export { store, persistor };