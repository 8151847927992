import React, { useEffect, useContext, useState } from "react";
import Contact from "../Contact/Contact";
import aboutPic from "../../assets/media/decoration/team.svg";
import { IGlobalContext } from "../../types/types";
import { GlobalContext } from "../../context/GlobalContext";
import { AnalysisIcon, ArrowDownIcon, ArrowUpIcon, ComprehensiveIcon, GraduateIcon, TalkIcon } from "../../assets/assetExport";
const arla = require("../../assets/media/certificates/arla.jpg");
const nrla = require("../../assets/media/certificates/nrla.png");
const prs = require("../../assets/media/certificates/prs.png");
const cmp = require("../../assets/media/certificates/cmp.png");
const dps = require("../../assets/media/certificates/dps.png");

const quotationLeft = require("../../assets/media/icons/quotation-l.png");
const quotationRight = require("../../assets/media/icons/quotation-r.png");

const features = [
    { img: <GraduateIcon width="30" height="30" />, nameEN: "Academically Proficient", nameZH: "专业", descEN: "Our team comprises Cambridge University graduates with over 15 years of senior management and professional experience in real estate and asset management.", descZH: "我们的创始团队成员均毕业于剑桥大学，在英国的地产置业领域拥有多年的管理经验。专业性是广大客户选择我们的首要因素。" },
    { img: <AnalysisIcon width="30" height="30" />, nameEN: "Thorough Analysis", nameZH: "分析", descEN: "Our real estate specialists conduct thorough research based on client requirements and provide comprehensive analyses to inform decision-making. Our property evaluation approach incorporates risk analysis, taking into account the macro-social economy and utilising the latest micro big data, with a combination of qualitative and quantitative analysis.", descZH: "对于房产投资组合，我们的资深房产专家悉心了解客户的需求，提供综合全面的房源数据分析，为最终决策提供建设性指导意见。具体的房产分析是在基于风险的评估策略（Risk-Based Assessment）下进行的。在考虑宏观社会经济的动态变化的同时，运用最前沿的微观大数据库，通过定性分析（Qualitative Analysis）与定量分析（Quantitative Analysis）相结合的方法，对房产建立全面的评分体系。" },
    { img: <TalkIcon width="30" height="30" />, nameEN: "Personalised Reports", nameZH: "定制", descEN: "By creating an integrated scoring system for over 20 factors, such as intrinsic value, potential, and rental yield, we provide each client with a customised optimal solution.", descZH: "我们运用的评分体系包括房源的固有价值、升值潜力、租金回报等20多个要素，并且根据客户实际需求进行加权。每一位客户都会得到一套量身定制的房产投资一体化解决方案。" },
    { img: <ComprehensiveIcon width="30" height="30" />, nameEN: "Extensive Services", nameZH: "全面", descEN: "Our expertise is further strengthened by our extensive network of property developers in prime locations across the UK and Europe, as well as our comprehensive value-added services, including mortgage services, legal advice, tax planning, education planning, and more.", descZH: "我们的专业团队还提供置业流程中必不可少的增值服务，包括合作银行贷款、律师对接、税务规划、以及子女择校入学等安居服务。" },
];

const certalraificates = [
    {
        img: arla,
        name: "ARLA Propertymark",
        descEN: "ARLA Propertymark membership ensures maximum protection for clients; offering Client Money Protection, Professional Indemnity insurance, membership of an independent redress scheme and being subject to Propertymark Conduct and Membership Rules and Disciplinary Procedures.",
        descZH: "英国权威房产监管机构 ARLA Propertymark 会员资格确保客户享有最大的保护，包括提供客户资金保护、专业责任保险、加入独立仲裁机构以及受制于Propertymark行为准则和会员规定以及纪律程序。",
        descMoreEN: [
            "This extract from the ARLA website explains more about the benefits to you as a Landlord from our membership of the scheme:-",
            "The Association of Residential Lettings Agents (ARLA) was formed in 1981 as the professional and regulatory body for letting agents in the UK. It was recognised that the requirements of the residential lettings market were so detailed and specific that a separate organisation was required to promote standards in this important and growing sector of the property market.",
            "ARLA has 1,800 member offices throughout the UK including large multi-branch national companies as well as the smaller single office practices. Membership is achieved only by agents who demonstrate that they have a thorough knowledge of their profession and that they conduct their business according to current best management practice.",
            "All members are required to have professional indemnity insurance cover. In addition they are covered by a bonding scheme which provides financial protection for client monies which have been misappropriated.",
            "ARLA members are governed by the Code of Practice providing a framework of ethical and professional standards at a level far higher than the law demands. The Association runs a series of meetings, training courses and seminars at local and national levels to ensure that members are kept fully up to date with the latest best practice. Examinations are held regularly and membership rules require all member offices to have professionally qualified staff by the year 2000. Members’ accounts are required to be audited annually ensuring that they conform to stringent accounting practice.",
            "The Association has its own complaints and disciplinary procedures so that any dispute is dealt with efficiently and fairly. Where necessary, suitable sanctions are imposed on the member.",
        ],
        descMoreZH: [
            "以下是内容翻译自 ARLA 网站，解释了我们加入该计划对您的好处：",
            "英国住宅租赁经纪人协会（ARLA）成立于1981年，是英国租赁经纪人的专业和监管机构。人们认识到，住宅租赁市场的要求非常详细和特定，需要一个独立的组织来推动这个重要且不断发展的房地产市场领域的标准。",
            "ARLA在整个英国拥有1,800个会员办事处，包括大型的多分支全国性公司以及小型的单一办事处。成为会员只有那些证明自己对自己的行业有全面的了解，并按照当前最佳管理实践进行业务的经纪人才能够达到。所有会员都必须拥有专业的责任保险。此外，他们还受到一个保证金计划的保护，该计划为被挪用的客户资金提供财务保护。",
            "ARLA会员受到《行业守则》的约束，该守则提供了一个道德和专业标准的框架，远远高于法律的要求。协会在地方和国家层面上举办一系列会议、培训课程和研讨会，以确保会员始终掌握最新的最佳实践。定期举行考试，会员规则要求到2000年前所有会员办事处都必须有专业资格的员工。会员的账目需要每年进行审计，以确保它们符合严格的会计实践。",
            "协会拥有自己的投诉和纪律程序，以便高效而公正地处理任何纠纷。必要时，对会员施加适当的制裁措施。",
        ],
        website: "https://www.propertymark.co.uk/",
    },
    {
        img: prs,
        name: "Property Redress Scheme",
        descEN: "The main purpose of the redress scheme is to resolve or settle unresolved complaints from consumers who have suffered a loss as a result of the actions of the member.",
        descZH: "物业管理计划认证的主要目的, 是解决或处理因会员的行为而导致的消费者损失与投诉。",
        descMoreEN: [
            "This extract from the Property Redress Scheme website explains more about the benefits to you from our membership of the scheme:-",
            "The Property Redress Scheme is a new, straightforward and easy to use consumer redress (ombudsman) scheme for Property Agents and Professionals.",
            "The main purposes of the Property Redress Scheme are to allow Agents to comply with their legal requirement to be a member of a government authorised consumer redress scheme and to settle or resolve complaints made by consumers against our Members.",
        ],
        descMoreZH: [
            "以下是内容翻译自 Property Redress Scheme 网站，解释了我们加入该计划对您的好处：",
            "财产救济计划是一个新的、简单易用的消费者救济（仲裁员）计划，适用于房地产经纪人和专业人士。",
            "财产救济计划的主要目的是让经纪人遵守法律要求，成为政府授权的消费者救济计划的成员，并解决消费者对我们会员的投诉或纠纷。",
        ],
        website: "https://www.theprs.co.uk/",
    },
    {
        img: cmp,
        name: "Propertymark Client Money Protection",
        descEN: "Landlords and clients are well protected under the Propertymark Client Money Protection Scheme.",
        descZH: "在Propertymark客户资金保护计划的保护下，房东和客户得到了很好的保护。",
        descMoreEN: [
            "This extract from the Propertymark Client Money Protection website explains more about the benefits to you from our membership of the scheme:-",
            "CMP is a scheme that reimburses landlords and tenants should a letting agent misappropriate their rent, deposit or other client funds. It has long been a requirement for ARLA Propertymark members to have CMP and has been a legal requirement for all letting agents since 1 April 2019.",
            "CMP is mandatory for every company that acts as an agency in property (including chattels auctioning) which handles client money or uses a third party to oversee client money. If this is the case, to become a member, Principals, Partners and Directors (PPDs) have to take out CMP with Propertymark unless they have CMP with Money Shield, RICS or the Law Society of Scotland.",
        ],
        descMoreZH: [
            "以下是内容翻译自 Propertymark Client Money Protection 网站，解释了我们加入该计划对您的好处：",
            "CMP（客户资金保护计划）是一个方案，如果出租经纪人挪用了房东或租户的租金、押金或其他客户资金，该方案将为其提供赔偿。长期以来，ARLA Propertymark的成员被要求具备CMP，并自2019年4月1日起成为所有出租经纪人的法定要求。",
            "CMP对于每个从事房地产代理（包括动产拍卖）并处理客户资金或使用第三方监管客户资金的公司都是强制性的。如果情况属实，为了成为成员，负责人、合伙人和董事（PPDs）必须向Propertymark购买CMP，除非他们已经与Money Shield、RICS或苏格兰法学会有CMP的保障。",
        ],
        website: "https://www.propertymark.co.uk/professional-standards/rules/cmp.html",
    },
    {
        img: nrla,
        name: "NRLA",
        descEN: "The National Residential Landlords Association is the UK's largest membership organisation for private residential landlords, supporting and representing over 100,000 members.",
        descZH: "英国国家私人住宅房东协会是英国最大的私人住宅房东会员组织，支持和代表超过100,000名会员。",
        descMoreEN: [
            "This extract from the NRLA website explains more about the benefits to you from our membership of the scheme:-",
            "From landlords renting out a single property to those with larger portfolios, we provide our members with the expertise, support, and resources needed for a rental sector that works for everyone. We represent our members and actively recognise the contribution landlords make to the rental sector and the wider society, in the provision of safe, legal, and secure homes."
        ],
        descMoreZH: [
            "以下是内容翻译自 NRLA 网站，解释了我们加入该计划对您的好处：",
            "无论是将单一物业出租的房东还是拥有更大房产组合的房东，我们为我们的会员提供所需的专业知识、支持和资源，以打造一个对所有人都有效的租赁行业。我们代表我们的会员，并积极认可房东在租赁行业和更广泛的社会中所作出的贡献，为提供安全、合法和稳定的住房。"
        ],
        website: "https://www.nrla.org.uk/",
    },
    {
        img: dps,
        name: "DPS",
        descEN: "Deposit Protection Service (DPS) is to safeguard tenants' deposits in the United Kingdom. It is a government-authorized scheme that ensures landlords protect their tenants' deposits by placing them in a secure and independent account.",
        descZH: "Deposit Protection Service（DPS）旨在保护英国租户的押金。这是一个经政府授权的计划，确保房东将租户的押金存放在安全独立的账户中。DPS帮助防止关于押金扣除的争议，并在租赁结束时提供公正的解决过程，以解决可能出现的冲突。",
        descMoreEN: [
            "The following explains the benefits to you from our membership of the scheme:-",
            "It provides a sense of security and peace of mind to tenants. The DPS acts as an impartial third-party custodian, holding the deposit funds throughout the tenancy period. This ensures that clients' deposits are safeguarded, protecting them from potential disputes or disagreements regarding the return of their deposit at the end of the tenancy.",
            "The DPS membership offers a transparent and efficient dispute resolution process. In the event of any conflicts between landlords and tenants, the DPS provides a reliable framework for resolving disputes. This ensures that disagreements are addressed fairly and promptly, minimizing any potential conflicts between the parties involved.",
            "The company's affiliation with the DPS demonstrates its commitment to maintaining high standards of professionalism and ethical practices. By being a member of the DPS, the company showcases its dedication to providing a reliable and trustworthy service to its clients. This affiliation instills trust and confidence in clients, as they can rely on the company to adhere to the DPS's guidelines and regulations.",
        ],
        descMoreZH: [
            "以下是内容解释了我们加入该计划对您的好处：",
            "DPS会员资格为租户提供安全感和放心。DPS作为一个公正的第三方托管机构，在整个租赁期间保管押金资金。这确保客户的押金得到保护，避免可能发生的关于押金退还的争议或不一致。",
            "DPS会员资格提供透明高效的争议解决流程。在房东和租户之间发生任何冲突时，DPS为解决争议提供可靠的框架。这确保争议得到公正和及时地解决，最大程度地减少各方之间的潜在冲突。",
            "公司与DPS的合作关系表明其致力于维持高水准的职业道德和行业规范。通过成为DPS的会员，公司展示了对向客户提供可靠和值得信赖的服务的承诺。这种合作关系让客户对公司产生了信任和信心，因为他们可以依靠公司遵守DPS的准则和规定。",
        ],
        website: "https://www.depositprotection.com/",
    }
];

export default function About() {
    const { lang, isMobile } = useContext(GlobalContext) as IGlobalContext;

    return (
        <div className=" text-gray-800">
            <div className="relative p-20  sm:p-32 sm:pt-60 flex h-full flex-col items-center gap-24" style={{ height: "calc(100vh - 63px)" }}>
                <h1 className={``}>{lang ? "About Us" : "关于我们"}</h1>
                <p className="text-center max-w-[600px] text-lg leading-loose">
                    {lang
                        ?
                        <span>NVRE is a top-tier real estate business consulting firm based in central London, certified by the UK's foremost real estate regulator - "ARLA Propertymark".<br /><br /> At NVRE, we are dedicated to delivering an unparalleled and seamless transaction experience, from market research to closing.</span>
                        :
                        <span>NVRE 是一家总部位于英国伦敦的高端地产咨询公司，{!isMobile && <br />}受英国权威房产监管机构ARLA Propertymark认证，{!isMobile && <br />}为高净值客户及家族企业提供专业高效的地产交易及租赁管理服务。</span>
                    }
                </p>
                <img className="absolute z-[-1] bottom-16 left-[15%] opacity-15 w-[70%] object-contain" src={aboutPic} />
            </div >
            <div className="p-10 xs:p-20 sm:p-32 pt-32 bg-tertiary-light/30">
                {/* <div className="p-32 bg-c-gray"> */}
                <div className="mb-16">
                    <h2 className="" >{lang ? "Features" : "公司的特点"}</h2>
                    <p className={`font-poster${lang ? "" : "-cn"} desc`} >{lang ? "Why us?" : "为什么选择我们?"}</p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {features.map(feature => {
                        return (
                            <div className="bg-secondary-light/10 px-8 py-12 pt-16 rounded-md">
                                <div className="flex items-center gap-4 mb-8">
                                    {feature.img}
                                    <h4 className="text-secondary-dark text-3xl font-extralight">{lang ? feature.nameEN : feature.nameZH}</h4>
                                </div>
                                <p className="leading-relaxed text-gray-600 text-lg">{lang ? feature.descEN : feature.descZH}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="p-10 xs:p-20 sm:p-32 pt-32">
                <div className="">
                    <h2 className="" >{lang ? "Certificates" : "公司资质"}</h2>
                    <p className={`font-poster${lang ? "" : "-cn"} desc`} >{lang ? "Do business that clients trust" : "做客户信赖的公司"}</p>
                </div>
                <p className="text-lg leading-loose mt-10  text-gray-600">
                    {lang
                        ?
                        `As a professional and reputable business, we take pride in adhering to all relevant regulations and obtaining the necessary certifications from the appropriate authorities. Our commitment to operating within the boundaries of the law is reflected in our longstanding reputation for conducting legitimate business practices.`
                        :
                        `我们是一家专业且信誉卓越的企业，所有的商业活动遵守相关法规，并获得相关机构的必要认证。`
                    }
                </p>
                <p className="text-lg leading-loose  text-gray-600">
                    {lang ? "" : "以下是我们公司在英国所持有的认证与资质。"}
                </p>
                <ul className="flex flex-col gap-20 mt-20">
                    {certalraificates.map(certificate => {
                        return (
                            <li className="border-t border-gray-100 pt-20">
                                <Certificate certificate={certificate} lang={lang} />
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className="p-10 xs:p-20 sm:p-32 pt-32 bg-tertiary-light/30">
                <Contact />
            </div>
        </div >
    );
}

function Certificate(props: any) {
    const [expand, setExpand] = useState(false);
    const { lang } = useContext(GlobalContext) as IGlobalContext;

    function handleExpand() {
        setExpand(!expand);
    }

    return (
        <div className="flex flex-col md:flex-row gap-10 items-start">
            <img className="w-60" src={props.certificate.img} alt={`logo for ${props.certificate.name}`} />
            <div>
                <h4 className="text-2xl mb-4">{props.certificate.name}</h4>
                <p className="leading-loose">
                    <span>{props.lang ? props.certificate.descEN : props.certificate.descZH}</span>
                    <button onClick={handleExpand}>
                        {expand ?
                            <p className="flex gap-1 items-center">&nbsp;<span className="text-sm text-main font-bold translate-y-[0.1rem]">{lang ? "SHOW LESS" : "收起"}</span><ArrowUpIcon width="15" height="15" /></p>
                            :
                            <p className="flex gap-1 items-center">&nbsp;<span className="text-sm text-main font-bold translate-y-[0.1rem]">{lang ? "SHOW MORE" : "显示更多"}</span><ArrowDownIcon width="15" height="15" /></p>
                        }
                    </button>
                </p>
                {expand && (
                    <div className="leading-loose mt-10">
                        {(props.lang ? props.certificate.descMoreEN : props.certificate.descMoreZH).map((i: any, index: any) => {
                            return (
                                <p className="my-4">{index === 0 ? <b className="text-secondary-dark/60">{i}</b> : i}</p>
                            );
                        })}
                        <p>{props.lang ? "Learn more about" : "了解更多关于"} {props.certificate.name}{props.lang ? " at" : "，请访问网站："} <a style={{ textDecoration: "underline" }} href={props.certificate.website} target="_blank">{props.certificate.website}</a></p>
                    </div>
                )}


            </div>
        </div>
    );
}
