import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { IGlobalContext } from "../../types/types";

import imgMap from "../../assets/media/developmentMaps/map.jpeg";
import imgList from "../../assets/media/developmentMaps/list.jpeg";
import logo from "../../assets/media/logo/company/nvreLogoPurple.png";
import Contact from "../../pages/Contact/Contact";
import { createDriver } from "../../pages/Sale/SalePage";

export default function ActionPopup() {
    const { setShowActionPopup, showActionPopup, setShowContactPopup, isRealMobile } = useContext(GlobalContext) as IGlobalContext;
    return (
        <div className={`fixed w-screen h-screen top-0 left-0 flex items-center justify-center z-[9999999999999999999] bg-transparent ${isRealMobile ? "h-[90vh] overflow-y-scroll" : ""}`}>
            <div className="mx-3 relative flex flex-col bg-white p-10 max-w-[800px] border shadow-lg rounded-md">
                <p
                    className="absolute top-4 right-4 hover:cursor-pointer text-black/40"
                    onClick={() => {
                        setShowActionPopup(false);
                    }}
                >
                    ✕
                </p>
                <img className="w-32 opacity-20" src={logo} alt="NVRE logo" />
                <p className="text-secondary-dark font-bold text-xl mt-5">
                    NVRE will assist you in finding the perfect development that suits
                    your needs.
                </p>
                <div className="text-nowrap flex gap-3 mt-5 self-center flex-col sm:flex-row">
                    <button
                        className="btn btn-sm  bg-main text-white rounded-sm"
                        onClick={() => {
                            setShowActionPopup(false);
                            const driverObj = createDriver();
                            driverObj.drive();
                        }}
                    >
                        Show me how
                    </button>
                    <button
                        className="btn btn-sm  btn-outline  text-main rounded-sm"
                        onClick={() => {
                            setShowActionPopup(false);
                        }}
                    >
                        I will explore on my own
                    </button>
                </div>
                <p className="font-bold mt-10 mb-2 text-lg text-secondary-dark border-t border-gray-100 pt-10">
                    You can save your choices as a pdf:
                </p>
                <div className={`flex gap-3`}>
                    <img
                        className={`${isRealMobile ? "h-32" : "h-40"} shadow-lg border`}
                        src={imgMap}
                        alt="a development map"
                    />
                    <img
                        className={`${isRealMobile ? "h-32" : "h-40"} shadow-lg border`}
                        src={imgList}
                        alt="a development map"
                    />
                </div>
                <div className="mt-12">
                    <p className="text-lg font-bold text-secondary-dark">
                        We are here to help!
                    </p>
                    <ul className="list-disc list-inside">
                        <li className="">Not sure how to start?</li>
                        <li className="">Not sure which property you should buy?</li>
                        <li className="">Need help with anything else?</li>
                    </ul>
                    <button
                        className="mt-2 text-main font-bold text-lg"
                        onClick={() => {
                            setShowActionPopup(false);
                            setShowContactPopup(true);
                        }}
                    >
                        Contact Us
                    </button>
                </div>
            </div>
        </div>
    );
}