import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeDevelopment,
  getFilteredDevelopmentIds,
} from "../../../features/developments/developmentsSlice";
import { SalesContext } from "../../../context/SalesContext";
import { IGlobalContext, ISalesContext } from "../../../types/types";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./MapView.scss";
import ReactDOMServer from "react-dom/server";
import Popup from "../Components/Common/Popup";
import { GlobalContext } from "../../../context/GlobalContext";
import { IncludeHouseIcon } from "../../../assets/assetExport";
import { getAllDevelopments } from "../../../features/developments/developmentsSlice";
import { selectLayoutHeaderHeight, selectLayoutMenubarHeight } from "../../../features/layouts/layoutsSlice";

mapboxgl.accessToken =
  "pk.eyJ1Ijoic3FpaSIsImEiOiJja3kzYXlnNHkwMGNpMnNwN2RtMGhxMjI4In0.RpbUFrXuGm4d_QjFNIYo9A";

export default function MapView(props: any) {
  const {
    lang,
    renderNum,
    filterPriceChanged,
    searchName,
    permission,
    showingTools,
    isSmallScreen,
    showContactPopup,
    isMobile,
    isRealMobile,
    showActionPopup,
    showPermissionPopup,
    adminOn,
    view,
  } = useContext(GlobalContext) as IGlobalContext;
  const dispatch = useDispatch();
  const allDevelopments = useSelector(getAllDevelopments);
  const filteredDevelopmentIds = useSelector(getFilteredDevelopmentIds);
  const salesContext = useContext(SalesContext) as ISalesContext;
  const { editMode } = useContext(SalesContext) as ISalesContext || { editMode: false };
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const markersRef = useRef<{ [key: string]: mapboxgl.Marker; }>({});
  const popupsRef = useRef<{ [key: string]: mapboxgl.Popup; }>({});
  const [mapShowing, setMapShowing] = useState(props.onHomePage || view === "map");
  const headerHeight = useSelector(selectLayoutHeaderHeight);
  const menubarHeight = useSelector(selectLayoutMenubarHeight);

  useEffect(() => {
    if (props.mapShowing || view === "map") {
      setMapShowing(true);
    }
  }, [view, props.mapShowing]);

  useEffect(() => {
    mapRef.current = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v12",
      center: [-0.145949, 51.5085224],
      zoom: 12,
    });

    // Add zoom and rotation controls to the map on the top left.
    mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-left");

    // Add geolocation controls to the map on the top left.
    mapRef.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      }),
      "top-left"
    );
  }, []);

  // useEffect(() => {
  //   if (view !== "map") return;
  //   loadDataOnMap();
  // }, [view]);

  useEffect(() => {
    if (!mapShowing) return;
    removeAllMarkersAndPopupsOnMap();
    loadDataOnMap();
  }, [searchName, mapShowing, filteredDevelopmentIds, dispatch]);


  // useEffect(() => {
  //   console.log(filteredDevelopments);
  // }, []);
  function removeAllMarkersAndPopupsOnMap() {
    Object.keys(popupsRef.current).forEach((id) => {
      popupsRef.current[id].remove();
      delete popupsRef.current[id];
    });

    Object.keys(markersRef.current).forEach((id) => {
      markersRef.current[id].remove();
      delete markersRef.current[id];
    });

    // markersRef.current[buttonId].remove();
    // popupsRef.current[buttonId].remove();
    // dispatch(removeDevelopment(allDevelopments[buttonId]));

    // delete markersRef.current[buttonId];
    // delete popupsRef.current[buttonId];
  }

  function loadDataOnMap() {
    filteredDevelopmentIds.forEach((id: any, index: any) => {
      if (allDevelopments[id] && !markersRef.current[id]) {
        const development = allDevelopments[id];
        // POPUP
        const MyPopup = (
          <GlobalContext.Provider
            value={{
              lang: lang,
              setLang: () => { },
              view: view,
              setView: () => { },
              renderNum: renderNum,
              isMobile: isMobile,
              setIsMobile: () => { },
              isRealMobile: isRealMobile,
              setIsRealMobile: () => { },
              isSmallScreen: isSmallScreen,
              setIsSmallScreen: () => { },
              permission: permission,
              setPermission: () => { },
              showingTools: showingTools,
              setShowingTools: () => { },
              showContactPopup: showContactPopup,
              setShowContactPopup: () => { },
              showPermissionPopup: showPermissionPopup,
              setShowPermissionPopup: () => { },
              adminOn: adminOn,
              setAdminOn: () => { },
              showActionPopup: showActionPopup,
              setShowActionPopup: () => { },
              searchName: searchName,
              setSearchName: () => { },
              filterPriceChanged: filterPriceChanged,
              setFilterPriceChanged: () => { },
            }}
          >
            <SalesContext.Provider value={salesContext}>
              <Popup obj={development} isMap={true} />
            </SalesContext.Provider>
          </GlobalContext.Provider>
        );

        const html = ReactDOMServer.renderToString(MyPopup);

        const popup = new mapboxgl.Popup({
          offset: 25,
          className: "id-" + development.id,
        })
          .setHTML(html)
          .setMaxWidth("none");
        popupsRef.current[development.id] = popup;

        // MARKER
        const jsxString = ReactDOMServer.renderToStaticMarkup(
          <IncludeHouseIcon width="15" height="15" />
        );
        const domParser = new DOMParser();
        const svgElement = domParser.parseFromString(
          jsxString,
          "image/svg+xml"
        ).documentElement;
        const svgString = new XMLSerializer().serializeToString(svgElement);
        const svgAsDataURL =
          "data:image/svg+xml," + encodeURIComponent(svgString);

        enum PurpleShades {
          Base99 = "#edeaf0",
          Base7 = "#edeaf0",
          Base6 = "#edeaf0",
          Base5 = "#edeaf0",
          Base4 = "#edeaf0",
          Base3 = "#edeaf0",
          Base2 = "#edeaf0",
          Base1 = "#edeaf0",
        }

        const color =
          PurpleShades[
          ("Base" + development.zoneMain) as keyof typeof PurpleShades
          ];

        const MyMarker = (
          <div
            id={"marker" + index}
            className={`border w-[100px] h-[30px] whitespace-nowrap border-secondary-dark/10 shadow-md rounded-lg font-normal text-secondary-dark flex flex-col justify-center gap-1 p-1 px-2 hover:cursor-pointer`}
            style={{ backgroundColor: color }}
          >
            <div className="absolute inset-0 w-[30%] ml-auto rounded-lg bg-gradient-to-r from-transparent to-tertiary-light pointer-events-none"></div>
            <p className="flex gap-2 items-center pr-2 overflow-hidden">
              {development.house && (
                <img className="opacity-60" src={svgAsDataURL} alt="House Icon" />
              )}
              {development.name}
            </p>
            <button
              id={String(development.id)}
              className="marker-remove absolute top-[-10px] right-[-10px] text-lg bg-secondary-light p-5 rounded-full w-5 h-5 items-center justify-center text-white shadow-md"
            >
              ✕
            </button>
          </div>
        );

        const markerHtml = ReactDOMServer.renderToString(MyMarker);

        const markerEl = document.createElement("div");
        markerEl.innerHTML = markerHtml;
        markerEl.addEventListener("mousedown", handleRemoveDevelopment);

        const marker = new mapboxgl.Marker(markerEl)
          .setLngLat([development.longitude, development.latitude])
          .setPopup(popup)
          .addTo(mapRef.current ? mapRef.current : new mapboxgl.Map());

        marker
          .getElement()
          .addEventListener("mouseenter", () => marker.togglePopup());
        marker
          .getElement()
          .addEventListener("mouseleave", () => marker.togglePopup());

        markersRef.current[development.id] = marker; // Store the marker instance
        popupsRef.current[development.id] = popup;
      }
    });
  }

  function handleRemoveDevelopment(event: any) {
    if (!event.target.matches(".marker-remove")) return;

    // Read the id of the button
    const buttonId = event.target.id;

    // Remove the marker from the map
    if (markersRef.current[buttonId]) {
      markersRef.current[buttonId].remove();
      delete markersRef.current[buttonId];
    }

    // Remove the popup from the map
    if (popupsRef.current[buttonId]) {
      popupsRef.current[buttonId].remove();
      delete popupsRef.current[buttonId];
    }

    // Dispatch the removeDevelopment action
    dispatch(removeDevelopment(+buttonId));


    event.stopPropagation();
  }

  // useEffect(() => {
  //   if (view !== "map") return;
  //   const els = document.querySelectorAll(".marker-chinese");
  //   els.forEach((el: any) => {
  //     el.style.display = lang ? "none" : "block";
  //   });
  // }, [lang, view]);

  // useEffect(() => {
  //   if (!mapShowing) return;
  //   const els = document.querySelectorAll(".marker-remove");
  //   els.forEach((el: any) => {
  //     el.style.display = editMode ? "flex" : "none";
  //   });
  // }, [editMode, mapShowing]);

  return (
    <div
      id="map"
      style={{
        height: `calc(100vh - ${headerHeight + menubarHeight}px)`,
        width: "100%",
      }}
      className={`${props.rounded ? "rounded-xl" : ""} ${props.onHomePage ? "hide" : (editMode ? "show" : "hide")}`}
    ></div>
  );
}
