// Libraries
import React, { useContext, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

// React components
import TubeView from "./Views/TubeView";
import ListView from "./Views/ListView";
import TableView from "./Views/TableView";
import MapView from "./Views/MapView";
import Filter from "./Controls/Filter";
import MenuBar from "./Controls/MenuBar";
import { SpinnerCircles } from "../../util/Spinners/Spinners";

// React context
import { SalesContainer, SalesContext } from "../../context/SalesContext";
import { IGlobalContext, ISalesContext } from "../../types/types";

// Redux slices
import { AppDispatch } from "../../app/store";
import {
  getAllDevelopmentsStatus,
  getFilteredDevelopmentIds,
} from "../../features/developments/developmentsSlice";
import InfoCollection from "../../items/InfoCollection/InfoCollection";
import Help from "../../items/Help/Help";
import { GlobalContext } from "../../context/GlobalContext";
import { selectLayoutHeaderHeight, selectLayoutMenubarHeight } from "../../features/layouts/layoutsSlice";



export default function SalePage() {
  return (
    <SalesContainer>
      <Sale />
      <InfoCollection />
    </SalesContainer>
  );
}

function Sale() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const viewParam = query.get("view");

  const saleContainerRef = useRef(null);
  const viewRef = useRef<HTMLDivElement>(null);
  const [mapViewMounted, setMapViewMounted] = useState(false);

  const { showFilter } =
    useContext(SalesContext) as ISalesContext;
  const { view, setView, permission, setShowActionPopup } = useContext(
    GlobalContext
  ) as IGlobalContext;


  const filteredDevelopmentIds = useSelector(getFilteredDevelopmentIds);
  const loading = useSelector(getAllDevelopmentsStatus);
  const headerHeight = useSelector(selectLayoutHeaderHeight);
  const menubarHeight = useSelector(selectLayoutMenubarHeight);

  useEffect(() => {
    if (
      localStorage.getItem("driver") &&
      localStorage.getItem("driver") === "true"
    ) {
      return;
    }

    setShowActionPopup(true);
    localStorage.setItem("driver", "true");
  }, []);





  useEffect(() => {
    if (viewParam) {
      setView(viewParam);
    }
  }, [viewParam]);

  useEffect(() => {
    if (view === "map") {
      setMapViewMounted(true);
    }
  }, [view]);

  if (loading === "loading") {
    return (
      <div className=" min-h-screen" style={{ marginTop: menubarHeight }}>
        <SpinnerCircles />
      </div>
    );
  }

  return (
    <div
      className={`max-w-[1800px] mx-auto relative z-0 flex flex-col overflow-hidden`}
      ref={saleContainerRef}
    >
      <MenuBar
        count={filteredDevelopmentIds.length}
        showSort={view === "list"}
      />
      <div
        ref={viewRef}
        className={`sale-content grid-svg-container  bg-gray-50 ${showFilter && "mr-[400px]"
          }`}
        style={{
          minHeight: "calc(100vh - " + (menubarHeight + headerHeight) + "px)",
          marginTop: menubarHeight + headerHeight + "px",
        }}
      >
        <div
          style={{
            position: view === "list" ? "relative" : "absolute",
            left: view === "list" ? "auto" : "100vw",
          }}
        >
          <ListView />
        </div>
        <div
          className="w-full"
          style={{
            position: view === "tube" ? "relative" : "absolute",
            left: view === "tube" ? "auto" : "0vw",
          }}
        >
          <TubeView />
        </div>
        {permission > 4 && view === "table" && <TableView />}
        {mapViewMounted && (
          <div
            style={{
              display: view === "map" ? "block" : "none",
            }}
            className="w-screen"
          >
            <MapView rounded={false} onHomePage={false} />
          </div>
        )}
      </div>
      <Filter viewRef={viewRef} />
      <Help
        playTutorial={() => {
          const driverObj = createDriver();
          driverObj.drive();
        }}
      />
    </div>
  );
}

/* FOR HIDE AND SHOW HEADER
    useEffect(() => {
      let timeoutId: any = null;
      let scrollTimeoutId: any = null;
      let lastScrollTop = 0;
      // let isScrolling = false;
  
      const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        // isScrolling = true;
  
        // if (timeoutId !== null) {
        //   clearTimeout(timeoutId);
        // }
  
        // timeoutId = setTimeout(() => {
        //   isScrolling = false;
        // }, 1000);
  
        if (scrollTop > lastScrollTop) {
          // Scrolling down
          setHeaderHeight("0");
        } else if (scrollTop == 0) {
          // User has reached the top of the page
  
  
  
  
          clearTimeout(scrollTimeoutId);
          clearTimeout(timeoutId);
  
          scrollTimeoutId = setTimeout(() => {
            // User has stopped scrolling at the top for 1 second
            console.log("User has stopped scrolling");
            timeoutId = setTimeout(() => {
              // Check if the user has scrolled up again after 1 second
              const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
              if (currentScrollTop < lastScrollTop) {
                console.log("User has scrolled up");
                setHeaderHeight(null);
              } else if (currentScrollTop > lastScrollTop) {
                console.log("User has scrolled down");
              } else {
                console.log("User has not scrolled");
              }
              // Update lastScrollTop only if the user has scrolled
              if (currentScrollTop !== lastScrollTop) {
                lastScrollTop = currentScrollTop;
              } else {
                console.log("User has scrolled up");
                setHeaderHeight(null);
              }
            }, 100);
          }, 1000);
        }
  
        lastScrollTop = scrollTop;
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
        if (timeoutId !== null) {
          clearTimeout(timeoutId);
        }
        if (scrollTimeoutId !== null) {
          clearTimeout(scrollTimeoutId);
        }
      };
    }, []);
    */

export function createDriver() {
  return driver({
    popoverClass: "driverjs-theme",
    showProgress: true,
    steps: [
      {
        element: "#driver_views",
        popover: {
          title: "Views",
          description:
            "This section allows you to switch between different views of the developments.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#driver_filter",
        popover: {
          title: "Filter",
          description:
            "Here you can apply various filters to refine the developments displayed.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#driver_download_pdf",
        popover: {
          title: "Download PDF",
          description: "Click here to download the data in PDF format.",
          side: "bottom",
          align: "start",
        },
      },
      {
        element: "#driver_tools",
        popover: {
          title: "Tools",
          description:
            "This section contains tools for that are useful for (soon-to-be) property owners.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#driver_edit",
        popover: {
          title: "Edit Mode",
          description: "Switch to edit mode to make changes to the data.",
          side: "top",
          align: "start",
        },
      },
      {
        popover: {
          title: "That's it!",
          description:
            "That concludes the tour. Enjoy exploring developments in London!",
        },
      },
    ],
  });
}