import React, { useContext, useEffect, useState } from "react";
import rentBackground from "../../assets/media/decoration/buildings.svg";
import { IGlobalContext } from "../../types/types";
import { GlobalContext } from "../../context/GlobalContext";
import { ArchiveIcon, CalculateIcon, CleanIcon, CreditIcon, DocumentIcon, MaintenanceIcon, MoneyIcon, NewTabIcon, SearchIcon, SecurityIcon, SuitcaseIcon, ToolsIcon } from "../../assets/assetExport";
import styles from "./Rent.module.scss";

const priceListEnglish = require("../../assets/file/NVRE LETTING PROCEDURE & PRICE LIST.pdf");
const priceListChinese = require("../../assets/file/NVRE租赁流程及费用清单.pdf");
const iconHeight = "30";
const rentingServices = {
    pre: [
        { img: <SearchIcon width={iconHeight} height={iconHeight} />, nameEN: "Matching", nameZH: "宣传与匹配", descEN: "Property listings and finding the most suitable tenants", descZH: "登记与宣传房产信息，寻找最合适的租户" },
        { img: <SecurityIcon width={iconHeight} height={iconHeight} />, nameEN: "Security Check", nameZH: "安全检查", descEN: "Arrange all required security checks on your behalf in accordance with UK housing regulations", descZH: "遵循英国住房法规安排所有必要的安全检查" },
        { img: <MoneyIcon width={iconHeight} height={iconHeight} />, nameEN: "Deposit", nameZH: "押金管理", descEN: "Register the deposit paid by the tenant during the tenancy period on your behalf in accordance with the relevant legal provisions", descZH: "根据相关法律规定，代房东登记租客在租赁期间支付的押金" },
        { img: <CreditIcon width={iconHeight} height={iconHeight} />, nameEN: "Credit Check", nameZH: "信用调查", descEN: "Financial credit checks to ensure the tenant's ability to pay", descZH: "财务信用检查以确保租户的支付能力" },
        { img: <DocumentIcon width={iconHeight} height={iconHeight} />, nameEN: "Tenancy Agreement", nameZH: "合同", descEN: "Drafting and formulating tenancy agreement in accordance with the terms of UK housing legislation", descZH: "根据英国住房立法的条款起草和制定租赁协议" },
        { img: <ArchiveIcon width={iconHeight} height={iconHeight} />, nameEN: "Inventories", nameZH: "家具财产", descEN: "Arranging move-in/move-out inventories, and producing inventory reports", descZH: "按需安排家具，并生成财产报告" },
    ],
    during: {
        maintenance: [
            { img: <MaintenanceIcon width={iconHeight} height={iconHeight} />, nameEN: "Inspections", nameZH: "检查", descEN: "Regular property maintenance inspections", descZH: "定期房屋维护检查" },
            { img: <ToolsIcon width={iconHeight} height={iconHeight} />, nameEN: "Repair", nameZH: "维修", descEN: "Emergency repair services", descZH: "紧急维修服务" },
            { img: <CleanIcon width={iconHeight} height={iconHeight} />, nameEN: "Cleaning", nameZH: "卫生", descEN: "Organise professional cleaning and maintenance services", descZH: "安排专业的清洁和维护服务" },
            { img: <MoneyIcon width={iconHeight} height={iconHeight} />, nameEN: "Rent Guarantee", nameZH: "租金保障", descEN: "Agent rental guarantee insurance service for landlords if required", descZH: "如需可为房东代办租房保障保险服务" },
        ],
        finance: [
            { img: <SuitcaseIcon width={iconHeight} height={iconHeight} />, nameEN: "Rent", nameZH: "租金", descEN: "Rent collection service", descZH: "收租服务" },
            // { img: check, nameEN: "Tax - free", nameZH: "免税", descEN: "Tax-free application arrangements for rental income of overseas owners", descZH: "为海外业主租金收入免税申请安排" },
            { img: <CalculateIcon width={iconHeight} height={iconHeight} />, nameEN: "Income Tax", nameZH: "所得税", descEN: "Assist owners with annual tax and rental income calculations", descZH: "协助房东计算年度税收和租金收入" },

        ]
    }
};
export default function Rent() {
    const { lang } = useContext(GlobalContext) as IGlobalContext;
    const [preLeasingPresenting, setPreLeasingPresenting] = useState(true);

    const showPreLeasing = () => {
        setPreLeasingPresenting(true);
    };

    const showDuringLeasing = () => {
        setPreLeasingPresenting(false);
    };

    return (
        <div className="">
            {/* HOME */}
            <div className="relative">
                <div className={`${styles.home} px-10 xs:px-20 sm:px-32 py-20 sm:pt-52 flex flex-col items-center gap-20`} >
                    <h1 className={``}>{lang ? "To Rent" : "租赁管理"}</h1>
                    <p className="text-center max-w-[600px] text-lg leading-loose">
                        <span className="block">{lang ? `As a certified ARLA member, NVRE provides local and overseas landlords with one-stop-shop services, including property handover, contract generation, inventory report, rent collection, and property management. Our specialist letting team in London provides you with top-quality services to ease the pressure you may encounter in property letting and management. Using our extensive network and innovative marketing techniques, we are able to efficiently connect landlords and suitable tenants in the shortest time possible.` : `作为英国权威房产监管机构ARLA Propertymark认证机构，NVRE 为本地和海外业主提供一站式服务，包括但不限于房产租赁对接、租赁合同起草制定、房屋财产报告、租金收取存管和物业管理服务。 在英国，房屋租赁管理是房东的主要责任，如果您希望轻松当甩手掌柜式的房东，请把您的房产租赁委托给我们在伦敦的专业租赁团队。我们将为您提供最优质的服务，以减轻您在房产租赁和管理中可能遇到的压力。`} </span>
                        <br />
                        <span className="block">{lang ? `Property management is the main responsibility of the landlord. Under the UK Landlord and Tenant Clause (1985), tenants have the right to keep the rent if the landlord fails to make timely repairs in certain respects.` : `我们在伦敦拥有本土租赁平台以及资源，在团队的专业和努力下，我们始终与房东和租户保持高效的沟通。`}</span>
                    </p>
                </div >
                <img className="absolute z-[-1] bottom-0 left-0 opacity-40 w-[100%] object-contain" src={rentBackground} alt="building illustartion for decoration" />
            </div>

            {/* SERVICES */}
            <div className="p-10 xs:p-20 sm:p-32 pt-32">
                <h2>{lang ? "Services" : "我们的服务"}</h2>
                <p className="mt-6 leading-loose">{lang ? `Our company provides comprehensive services to assist clients with all aspects of leasing. We understand that leasing a property can be a complex and daunting process, which is why we strive to make it as easy and stress-free as possible for our clients. Our services are designed to help clients before and during the leasing process.` : `我们提供英国租赁管理的全方位服务，能协助您处理租赁的每个环节和各个方面，让您轻松无忧坐享房东收益。`}</p>
                <p className="leading-loose">{lang ? `` : `以下是我们租赁管理服务的各个项目。`}</p>
                <div className="mt-32">
                    <h3 className="text-main">{lang ? "Services Pre Leasing" : "预租服务"}</h3>
                    <p className={`${lang ? "font-poster" : "font-poster-cn"} desc`}>{lang ? "Getting it ready" : "准备好出租"}</p>
                    <div className={`${styles.itemsGrid} mt-20`}>
                        {rentingServices.pre.map(item => {
                            return (
                                <div className={styles.itemContainer} key={item.nameEN}>
                                    <div>{item.img}</div>
                                    <div>
                                        <h4>{lang ? item.nameEN : item.nameZH}</h4>
                                        <p>{lang ? item.descEN : item.descZH}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="mt-40">
                    <h3 className="text-main">{lang ? "Services During Leasing" : "租赁期间服务"}</h3>
                    <p className={`${lang ? "font-poster" : "font-poster-cn"} desc`}>{lang ? "We take care of everything" : "我们负责一切事务"}</p>
                    <div className="mt-20">
                        <h4 className="font-medium text-gray-400/70 text-xl mb-6">{lang ? "MAINTENANCE" : "维护"}</h4>
                        <div className={styles.itemsGrid}>
                            {rentingServices.during.maintenance.map(item => {
                                return (
                                    <div className={styles.itemContainer} key={item.nameEN}>
                                        <div>{item.img}</div>
                                        <div>
                                            <h4>{lang ? item.nameEN : item.nameZH}</h4>
                                            <p>{lang ? item.descEN : item.descZH}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <h4 className="font-medium text-gray-400/70 text-xl mb-6 mt-20">{lang ? "FINANCES" : "财务"}</h4>
                        <div className={styles.itemsGrid}>
                            {rentingServices.during.finance.map(item => {
                                return (
                                    <div className={styles.itemContainer} key={item.nameEN}>
                                        <div>{item.img}</div>
                                        <div>
                                            <h4>{lang ? item.nameEN : item.nameZH}</h4>
                                            <p>{lang ? item.descEN : item.descZH}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-16 p-10 xs:p-20 sm:p-32 bg-secondary-light/10 text-gray-700">
                <h2 className="mb-4">{lang ? "Letting Procedure and Price list" : "租赁流程和价格表"}</h2>
                <p>{lang ? "Please find it" : "请在此处查看"} <a className="inline-flex items-center gap-1 text-secondary-dark/60 font-bold" href={priceListEnglish} target="_blank" >{lang ? "here [English]" : "这里 [英文]"}<NewTabIcon width="18" height="18" /></a> {lang ? "and" : "和"} <a className="inline-flex items-center gap-1 text-secondary-dark/60 font-bold" href={priceListChinese} target="_blank" style={{ textDecoration: "underline" }}>{lang ? "here [Chinese]" : "这里 [中文]"} <NewTabIcon width="18" height="18" /></a>.</p>
            </div>
        </div >
    );
};