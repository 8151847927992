import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    "scale-level": 1
};

// SLICE OBJECT
export const mapzoomSlice = createSlice({
    name: "mapzoom",
    initialState,
    reducers: {
        updateScaleLevel: (state, action) => {
            state["scale-level"] = action.payload;
        },
    }
});



// SELECTORS
export const selectMapzoomAll = (state: any) => state.mapzoom;
export const selectMapzoomScaleLevel = (state: any) => state.mapzoom["scale-level"];



// EXPORTS
export const {
    updateScaleLevel
} = mapzoomSlice.actions;

export default mapzoomSlice.reducer;