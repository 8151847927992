import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./MenuBar.module.scss";
import { SalesContext } from "../../../context/SalesContext";
import { GlobalContext } from "../../../context/GlobalContext";
import { IGlobalContext, ISalesContext } from "../../../types/types";
import { useNavigate, useLocation } from "react-router-dom";
import {
  DownloadIcon,
  EditIcon,
  FilterIcon,
  ListIcon,
  MagnifierIcon,
  MapIcon,
  RemoveFillIcon,
  TableIcon,
  TubeIcon,
} from "../../../assets/assetExport";
import { generateMapPDF } from "../../../util/PDFRelated/generatePDF";
import { getAllDevelopments, getFilteredDevelopmentIds } from "../../../features/developments/developmentsSlice";
import { selectLayoutHeaderHeight, setLayoutValue } from "../../../features/layouts/layoutsSlice";
import { setPdfStatus } from "../../../features/states/statesSlice";

export default function MenuBar(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const filteredDevelopmentIds = useSelector(getFilteredDevelopmentIds);
  const allDevelopments = useSelector(getAllDevelopments);
  const {
    showFilter,
    setShowFilter,
    sort, setSort,
    editMode,
    setEditMode,
  } = useContext(SalesContext) as ISalesContext;
  const {
    view,
    setView,
    isMobile,
    isRealMobile,
    lang,
    permission,
    searchName,
    setSearchName,
  } = useContext(GlobalContext) as IGlobalContext;

  const [tempDevelopments, setTempDevelopments] = useState<any>([]);
  const menubarRef = useRef<HTMLDivElement | null>(null);
  const headerHeight = useSelector(selectLayoutHeaderHeight);

  function Button({
    condition,
    en,
    zh,
    Icon,
    handleOnClick,
    min,
    id,
  }: {
    condition: boolean;
    en: string;
    zh: string;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    handleOnClick: any;
    min?: number;
    id?: string;
  }) {
    return (
      <button
        id={id ? id : ""}
        className={`${condition ? styles.chosen : ""} ${!isRealMobile ? (min && lang && "w-[9rem]") : ""}`}
        onClick={handleOnClick}
      >
        <Icon width="18" height="18" />
        <p className="whitespace-nowrap">{lang ? en : zh}</p>
      </button>
    );
  }

  useEffect(() => {
    let resizeTimer: ReturnType<typeof setTimeout>;

    const handleResize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {

        if (menubarRef.current && menubarRef.current.offsetHeight > 0) {
          dispatch(setLayoutValue({ name: "menubarHeight", value: menubarRef.current.offsetHeight }));
        }
      }, 300);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      if (resizeTimer) clearTimeout(resizeTimer);
    };
  }, []);

  useEffect(() => {
    if (menubarRef.current) {
      menubarRef.current.style.top = headerHeight - 1 + "px";
    }
  }, [headerHeight]);

  return (
    <div
      ref={menubarRef}
      className={`flex flex-col px-10 bg-white w-full z-[999] border-b fixed left-0 text-gray-600 font-medium text-sm`}
      // style={{ top: `${headerHeight}px` }}
      id="menu-bar"
    >

      {/* SEARCH BAR */}
      <form
        className={`flex gap-1 pt-1 w-full mx-auto`}
        style={{ maxWidth: "calc(1800px - 5rem)" }}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <label className="input input-sm input-bordered border-gray-300 flex items-center gap-2 w-full bg-white">
          <MagnifierIcon width="15" height="15" />
          <input
            type="text"
            className="grow leading-normal"
            placeholder="Search a London Development ..."
            value={searchName}
            onChange={(e: any) => {
              setSearchName(e.target.value);
            }}
          />
          <p
            className="hover:cursor-pointer p-2"
            onClick={() => {
              setSearchName("");
            }}
          >
            ✕
          </p>
        </label>
        {/* <button type="submit" className="btn w-40">Search</button> */}
      </form>

      {/* THE REST */}
      <div className={`${styles.grid} ${permission > 4 ? styles.admin : styles.public} ${isMobile && permission > 4 ? styles.break : ""}`}>
        <DownloadElement />
        <EditFilter />
        <ResultsViews />
      </div>

      <div className="absolute left-[10px] mt-[10px] border-[0.5px] border-gray-200 rounded-full top-full  z-10 bg-white bg-opacity-60  backdrop-blur-sm">
        <SortBy />
        {/* <div className="relative h-screen flex items-center justify-center">
              <div className="relative z-10 p-6 text-white bg-white bg-opacity-20 rounded-lg backdrop-blur-md">
                <p>This content is placed over a semi-transparent and blurred background.</p>
              </div>
            </div> */}
      </div>

    </div>
  );

  function DownloadElement() {
    return (
      <div className={styles.downloadElement}>
        {/* <div className={styles.btnDownloadSmallScreen}> */}
        <DownloadButton {...props} />
      </div>
    );
  }

  function EditFilter() {
    return (
      <div className={`  flex items-center text-sm pt-3 ${styles.editFilter}`}>
        <div className={`${styles.buttonGroup}  gap-4`}>
          {permission > 4 && (
            <Button
              id="driver_edit"
              condition={editMode}
              en={`EDIT${!isRealMobile ? " MODE" : ""} ${!isRealMobile ? (editMode ? " ON" : "OFF") : ""}`}
              zh={`${editMode ? "完成" : "开启"}编辑`}
              Icon={EditIcon}
              handleOnClick={() => {
                setEditMode(!editMode);
              }}
              min={400}
            />
          )}
          <Button
            id="driver_filter"
            condition={showFilter}
            en={`FILTER${!isRealMobile ? (showFilter ? " SHOWN" : " HIDDEN") : ""}`}
            zh={`${showFilter ? "隐藏" : "展开"}筛选`}
            Icon={FilterIcon}
            handleOnClick={() => {
              setShowFilter(!showFilter);
            }}
            min={400}
          />
        </div>
      </div>
    );
  }

  function ResultsViews() {
    return (
      <div className={`${styles.resultsViews} ${isRealMobile ? "" : "gap-1"} flex  items-center pt-3 h-full`}>
        <p className={`whitespace-nowrap border-r ${isRealMobile ? "pr-2 text-xs" : "pr-6"} mb-3`}>
          <span className="font-mono">
            {props.count}
            {props.count < 10 && <span>&nbsp;</span>}
          </span>{" "}
          {lang ? "results" : "个楼盘"}
        </p>
        <div id="driver_views" className={`${styles.buttonGroup} ${isRealMobile ? "gap-3" : "gap-8"}`}>
          <Button
            condition={view === "list"}
            en={`LIST ${!isMobile ? "VIEW" : ""}`}
            zh="列表"
            Icon={ListIcon}
            handleOnClick={() => {
              setView("list");
              navigate({ ...location, search: "?view=list" });
            }}
          />
          {permission > 4 && (
            <Button
              condition={view === "table"}
              en={`TABLE ${!isMobile ? "VIEW" : ""}`}
              zh="表格"
              Icon={TableIcon}
              handleOnClick={() => {
                setView("table");
                navigate({ ...location, search: "?view=table" });
              }}
            />
          )}
          <Button
            condition={view === "tube"}
            en={`TUBE ${!isMobile ? "VIEW" : ""}`}
            zh="地铁图"
            Icon={TubeIcon}
            handleOnClick={() => {
              setView("tube");
              navigate({ ...location, search: "?view=tube" });
            }}
          />
          <Button
            condition={view === "map"}
            en={`MAP ${!isMobile ? "VIEW" : ""}`}
            zh="地图"
            Icon={MapIcon}
            handleOnClick={() => {
              setView("map");
              navigate({ ...location, search: "?view=map" });
            }}
          />
        </div>
        {/* <div className={styles.btnDownload}>
                <DownloadButton {...props} />
              </div> */}
      </div>
    );
  }

  function SortBy() {
    if (view !== "list") return null;

    return (
      <div className="m-2 px-4 text-xs">
        <form className="flex gap-2 whitespace-nowrap items-center text-inherit-size">
          <label
            htmlFor="sort-select"
            className="whitespace-nowrap text-inherit-size"
          >
            {lang ? "SORT BY: " : "排序方式："}
          </label>
          <select
            id="sort-select"
            className="select pb-1 select-xs text-inherit-size text-secondary-dark font-main-sans w-full max-w-x bg-transparent "
            value={sort}
            onChange={(e) => {
              // renderWithSort(e.target.value);
              setSort(e.target.value);
            }}
          >
            <option value="name">{lang ? "Name" : "名称"}</option>
            <option value="highest-price">
              {lang ? "Highest price" : "最高价格"}
            </option>
            <option value="lowest-price">
              {lang ? "Lowest price" : "最低价格"}
            </option>
            <option value="zone">{lang ? "Zone" : "地铁区"}</option>
          </select>
        </form>
      </div>
    );
  }
}

function DownloadButton(props: any) {
  const { lang, isMobile } = useContext(GlobalContext) as IGlobalContext;
  // const { setPdfStatus, } = useContext(SalesContext) as ISalesContext;
  const [printing, setPrinting] = useState(false);
  const dispatch = useDispatch();

  const printMap = async () => {
    dispatch(setPdfStatus("idle"));
  };

  return (
    <button
      onClick={printMap}
      id="driver_download_pdf"
      className={`${styles.btn}
      px-3 py-[0.35rem] bg-main ${props.count ? "hover:bg-secondary-dark" : ""
        } text-white font-normal shadow-xl flex items-center gap-3 rounded-sm text-white/90 ${!props.count ? "opacity-50 hover:cursor-not-allowed" : ""
        }`}
      disabled={!props.count}
    >
      <DownloadIcon width="12" height="12" />
      <p className={``}>
        {printing ? (
          lang ? (
            "Downloading ..."
          ) : (
            "下载中 ..."
          )
        ) : (
          <span>
            <span className="font-bold text-white whitespace-nowrap">
              {lang ? "DOWNLOAD PDF" : "PDF 下载"}{" "}
            </span>
            {!isMobile &&
              <>
                <span>
                  {props.count}&nbsp;{lang ? "results" : "个楼盘"}
                </span>{" "}
                <span>{lang ? "as PDF" : ""}</span>
              </>
            }
          </span>
        )}
      </p>
    </button>
  );
}
