
import React, { useEffect, useState, useLayoutEffect, useRef, useContext } from "react";
// import Slider from 'react-slick';
import "./Partners.css";
import { GlobalContext } from "../../../context/GlobalContext";
import { IGlobalContext } from "../../../types/types";
const berkeleyLogo = require("../../../assets/media/partners/berkeley-bw.png");
const barrettLogo = require("../../../assets/media/partners/barrett-bw.png");
const galliardLogo = require("../../../assets/media/partners/galliard-bw.png");
const ipglobalLogo = require("../../../assets/media/partners/ipglobal-bw.png");
const knightfrankLogo = require("../../../assets/media/partners/knightfrank-bw.png");
const northpropertygroupLogo = require("../../../assets/media/partners/northpropertygroup-bw.png");
const cbreLogo = require("../../../assets/media/partners/cbre-bw.png");
const jllLogo = require("../../../assets/media/partners/jll-bw.png");
const salboyLogo = require("../../../assets/media/partners/salboy.png");
const savillsLogo = require("../../../assets/media/partners/savills.png");
const hillLogo = require("../../../assets/media/partners/hill-bw.png");
const mountAnvilLogo = require("../../../assets/media/partners/mount-anvil.png");
const taylorWimpeyLogo = require("../../../assets/media/partners/taylor-wimpey.png");

const arrowLeft = require("../../../assets/media/icons/arrow-left-circled.png");
const arrowRight = require("../../../assets/media/icons/arrow-right-circled.png");

const partners = [
    berkeleyLogo,
    galliardLogo,
    mountAnvilLogo,
    taylorWimpeyLogo,
    salboyLogo,
    hillLogo,
    ipglobalLogo,
    knightfrankLogo,
    northpropertygroupLogo,
    cbreLogo,
    jllLogo,
    savillsLogo,
    barrettLogo
];


export default function Partners(props: any) {
    const [slideWidth, setSlideWidth] = useState<number | undefined>(undefined);
    const [currentIndex, setCurrentIndex] = useState(0);
    const { lang } = useContext(GlobalContext) as IGlobalContext;

    const editTransition = (action: any) => { // 0 is remove, 1 is add
        if (document.querySelector(".slider-list")) {
            (document.querySelector(".slider-list") as HTMLElement).style.transition = action ? "transform 0.5s ease-in-out" : "none";
        }
    };
    const slideLeft = () => {
        if (currentIndex + 1 > partners.length) {
            editTransition(0);
            setCurrentIndex(0);
            setTimeout(() => {
                editTransition(1);
                setCurrentIndex(1);
            }, 200);
        } else {
            editTransition(1);
            setCurrentIndex(currentIndex + 1);
        }
    };

    const slideRight = () => {
        if (currentIndex - 1 < 0) {
            editTransition(0);
            setCurrentIndex(partners.length);
            setTimeout(() => {
                editTransition(1);
                setCurrentIndex(partners.length - 1);
            }, 200);
        } else {
            editTransition(1);
            setCurrentIndex(currentIndex - 1);
        }
    };

    useEffect(() => {
        let sliderList = document.querySelector(".slider-list");
        let timer: any;
        if (!timer) {
            timer = setInterval(() => {
                slideRight();
            }, 1500);
        }

        const mouseEnterEvent = () => { clearInterval(timer); };
        const mouseLeaveEvent = () => {
            timer = setInterval(() => {
                slideRight();
            }, 1500);
        };
        if (sliderList) {
            sliderList.addEventListener("mouseenter", mouseEnterEvent);
            sliderList.addEventListener("mouseleave", mouseLeaveEvent);
        }

        return () => {
            clearInterval(timer);
            if (sliderList) {
                sliderList.removeEventListener("mouseenter", mouseEnterEvent);
                sliderList.removeEventListener("mouseleave", mouseLeaveEvent);
            }
        };
    });

    useEffect(() => {
        window.onresize = () => {
            if (document.querySelector(".slide")) {
                setSlideWidth(document.querySelector(".slide")!.getBoundingClientRect().width);
            }
        };

        return () => {
            window.onresize = null;
        };
    });

    useLayoutEffect(() => {
        setTimeout(() => {
            if (document.querySelector(".slide")) {
                setSlideWidth(document.querySelector(".slide")!.getBoundingClientRect().width);
            }
        }, 1500);
    }, []);


    return (
        <div className="partners">
            <div className="w-full">
                <h2 className="">{lang ? "Our Partners" : "合作伙伴"}</h2>
                <p className={`font-poster${lang ? "" : "-cn"} desc`}>{lang ? "Comprehensive Coverage" : "全面覆盖"}</p>
            </div>
            <div className="slider-container">
                <img src={arrowLeft} className="slider-arrow slider-arrow-left" onClick={slideLeft} />
                <div className="slider">
                    <div className="slider-list" style={{ transform: `translateX(-${currentIndex * ((slideWidth ?? 0) + 40)}px)` }} >
                        {partners.map(i => {
                            return (
                                <img src={i} key={`partners-${i}`} className="slide" />
                            );
                        })}
                        {partners.map(i => {
                            return (
                                <img src={i} key={`partners-${i}-cloned`} className="slide cloned" />
                            );
                        })}
                    </div>
                </div>
                <img src={arrowRight} className="slider-arrow slider-arrow-left" onClick={slideRight} />
            </div>
        </div >
    );
}
