import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from '@reduxjs/toolkit';

interface StatesState {
    pdfStatus: "idle" | "preparing" | "generating" | "ready" | "error" | "hide";
}

const initialState: StatesState = {
    pdfStatus: "hide"
};


// SLICE OBJECT
export const statesSlice = createSlice({
    name: "states",
    initialState,
    reducers: {
        setPdfStatus: (state, action: PayloadAction<StatesState['pdfStatus']>) => {
            state.pdfStatus = action.payload;
        }
    },
});

// SELECTORS
export const getPdfStatus = (state: any) => state.states.pdfStatus;

// EXPORTS
export const {
    setPdfStatus
} = statesSlice.actions;
export default statesSlice.reducer;
