import React, { useContext, useState } from "react";
import {
  CalculatorIcon,
  HouseIcon,
  MortgageIcon,
  ToolboxIcon,
  YieldIcon,
} from "../../assets/assetExport";
import { GlobalContext } from "../../context/GlobalContext";
import { IGlobalContext } from "../../types/types";
import styles from "./ToolMenu.module.scss";
import StampDutyPage from "../../pages/Services/StampDutyPage/StampDutyPage";
import MortgagePage from "../../pages/Services/MorgagePage/MortgagePage";
import RentalYieldPage from "../../pages/Services/YieldPage/YieldPage";
import RentEstimatePage from "../../pages/Services/RentEstimatePage/RentEstimatePage";

export default function ToolMenu() {
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState("");
  const { lang, showingTools, setShowingTools } = useContext(GlobalContext) as IGlobalContext;
  return (
    <div
      id="driver_tools"
      className={`fixed bottom-5 right-5 z-[999999999999] flex flex-col justify-between ${showingTools && "w-[650px] h-[700px]"
        }  ${showingTools ? "rounded-[1.8rem] bg-white p-2" : "p-0 rounded-3xl bg-fourth-light"
        } shadow-lg shadow-black/30 border border-gray-100`}
      style={{ minHeight: "0" }}
    >
      {showingTools && (
        <div className="overflow-y-scroll p-10">
          <p
            onClick={() => {
              setShowingTools(false);
              setShow(false);
              setSelected("");
            }}
            className="absolute top-3 right-5 text-xl bg-white/50 rounded-lg p-2 text-secondary-light hover:cursor-pointer hover:text-main"
          >
            ✕
          </p>
          {selected === "mortgage" && <MortgagePage page="tool" />}
          {selected === "stampduty" && <StampDutyPage page="tool" />}
          {selected === "rental" && <RentalYieldPage page="tool" />}
          {selected === "rent" && <RentEstimatePage page="tool" />}
        </div>
      )}
      <div
        className="hover:cursor-pointer"
        onClick={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        {
          // show || showingTools ? 
          (
            <ul
              className={`${styles.items} ${!showingTools ? "flex-row" : styles.opened
                } w-full justify-between bg-gray-100 rounded-3xl`}
            >
              {/* <ul
              className={`${styles.items} ${!showingTools ? "flex-col" : styles.opened
                } w-full justify-between bg-fourth-light rounded-3xl`}
            > */}
              <li
                onClick={() => {
                  setSelected("stampduty");
                  setShowingTools(true);
                }}
              >
                <p
                  className={`${selected === "stampduty" ? styles.selected : ""
                    } hover:cursor-pointer`}
                >
                  <CalculatorIcon width="18" height="18" />
                  {lang ? "Stamp Duty" : "印花税计算器"}
                </p>
              </li>
              <li
                onClick={() => {
                  setSelected("rental");
                  setShowingTools(true);
                }}
              >
                <p
                  className={`${selected === "rental" ? styles.selected : ""
                    } hover:cursor-pointer`}
                >
                  <YieldIcon width="18" height="18" />
                  {lang ? "Rental Yield" : "租金收益率"}
                </p>
              </li>
              <li
                onClick={() => {
                  setSelected("rent");
                  setShowingTools(true);
                }}
              >
                <p
                  className={`${selected === "rent" ? styles.selected : ""
                    } hover:cursor-pointer`}
                >
                  <HouseIcon width="18" height="18" />
                  {lang ? "Rent Estimator" : "租金估算器"}
                </p>
              </li>
              <li
                onClick={() => {
                  setSelected("mortgage");
                  setShowingTools(true);
                }}
              >
                <p
                  className={`${selected === "mortgage" ? styles.selected : ""
                    } hover:cursor-pointer`}
                >
                  <MortgageIcon width="18" height="18" />
                  {lang ? "Mortgage Calculator" : "贷款计算器"}
                </p>
              </li>
            </ul>
          )
          // : (
          //   <div className="flex items-center gap-2 px-2 opacity-80 bg-fourth-light">
          //     <ToolboxIcon width="18" height="18" />
          //     <span className="text-secondary-dark px-2  font-medium border border-transparent">
          //       {lang ? "Landlord Tools" : "房东实用工具"}
          //     </span>
          //   </div>
          // )
        }
      </div>
    </div>
  );
}
