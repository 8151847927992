import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Partners from "./Partners/Partners";
import { NavLink } from "react-router-dom";
import Contact from "../Contact/Contact";
import styles from "./HomePage.module.scss";

import { ReactComponent as house } from "../../assets/media/icons/svg/building.svg";
import { ReactComponent as manage } from "../../assets/media/icons/svg/document.svg";
import { ReactComponent as loan } from "../../assets/media/icons/svg/bank.svg";
import { ReactComponent as lawyer } from "../../assets/media/icons/svg/law.svg";
import { ReactComponent as tax } from "../../assets/media/icons/svg/pound.svg";
import { ReactComponent as school } from "../../assets/media/icons/svg/school.svg";
import { IGlobalContext } from "../../types/types";
import { GlobalContext } from "../../context/GlobalContext";
import { HashLink } from "react-router-hash-link";
import {
  BeachIcon,
  InvestIcon,
  RightArrowIcon,
  SofaIcon,
} from "../../assets/assetExport";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import {
  // selectFilterBedrooms,
  // selectFilterPrice,
  getAllDevelopments,

  getFilteredDevelopmentIds,
  setFilter,
  getFilters,
  setNestedFilter,
  setFilters,
  Filter,
  // getCurrentPriceRange,
  getIsPriceManuallySet,
  setPriceManuallySet,
} from "../../features/developments/developmentsSlice";
import MapView from "../Sale/Views/MapView";

const londonIllustration = require("../../assets/media/decoration/london-illustration.png");
const test = require("./test1.png");

export default function HomePage(props: any) {
  const {
    lang,
    filterPriceChanged,
    setFilterPriceChanged,
    searchName,
    setSearchName,
  } = useContext(GlobalContext) as IGlobalContext;
  const dispatch = useDispatch<AppDispatch>();
  const allDevelopments = useSelector(getAllDevelopments);
  // const developmentsStatus = useSelector(getAllDevelopmentsStatus);
  const service = [
    { img: loan, en: "Mortgage", zh: "银行贷款" },
    { img: house, en: "Buy a Property", zh: "房源挑选" },
    { img: lawyer, en: "Legal Services", zh: "律师推荐" },
    { img: manage, en: "Property Management", zh: "租赁管理" },
    { img: tax, en: "Tax Planning", zh: "税务规划" },
    { img: school, en: "School", zh: "子女入学" },
  ];

  const targetRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const scrollToContact = location.state && location.state.scrollToContact;
  const filteredDevelopmentIds = useSelector(getFilteredDevelopmentIds);
  const isPriceManuallySet = useSelector(getIsPriceManuallySet);

  const filter = useSelector(getFilters);

  const [localFilters, setLocalFilters] = useState<Filter>(filter);
  // const currentPriceRange = useSelector(getCurrentPriceRange);
  const displayPriceRange = localFilters.price;;
  // const displayPriceRange = filterPriceChanged ? localFilters.price : currentPriceRange;

  useEffect(() => {
    if (scrollToContact && targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [scrollToContact]);

  useEffect(() => {
    let i =
      document.querySelector(".services-title") &&
      document.querySelector(".services-title")!.getBoundingClientRect(); //TODO: workaround for sometimes some text doesn't show on frontpage when logo is clicked; this results in a repaint
  });

  const handleUpdateFilter = useCallback(() => {
    if (!allDevelopments) return;

    const filtersToApply = localFilters;
    dispatch(setFilters(filtersToApply));
  }, [localFilters, dispatch]);

  function updateFilterPrice(e: any, index: number) {
    const value = Number(
      e.target.value.trim().replace("£", "").replace(/,/g, "")
    );

    setLocalFilters((prevFilters: Filter) => {
      const newPrice = [...prevFilters.price];
      newPrice[index] = value;
      return {
        ...prevFilters,
        price: newPrice as [number, number]
      };
    });

    dispatch(setFilter({ filter: 'price', value: localFilters.price.map((p, i) => i === index ? value : p) }));
    dispatch(setPriceManuallySet(true));
  }

  // useEffect(() => {
  //   if (!isPriceManuallySet) {
  //     setLocalFilters((prevFilters: any) => ({
  //       ...prevFilters,
  //       price: currentPriceRange
  //     }));
  //   }
  // }, [currentPriceRange, isPriceManuallySet]);

  function updateFilterBedrooms(e: React.ChangeEvent<HTMLInputElement>) {
    setLocalFilters((prevFilters: Filter) => ({
      ...prevFilters,
      bedrooms: {
        ...prevFilters.bedrooms,
        [e.target.name]: e.target.checked
      }
    }));
  }

  return (
    <div className="text-gray-800">
      <section
        className="pb-20 flex flex-col items-center justify-center relative"
        style={{ minHeight: "calc(100vh - 63px)" }}
      >
        <div
          className={`${styles.filterContainer} mt-40 mx-32 px-6 py-6 relative flex  flex-col items-center gap-2 `}
        >
          <p className="text-gray-700 text-2xl font-poster2 mb-2">
            <NavLink
              to="/property?view=map"
              className="px-2 py-1 rounded-lg bg-tertiary-light/60 hover:bg-tertiary-light text-secondary-dark text-inherit-size font-poster2"
              style={{ textTransform: "none" }}
            >
              Browse properties
            </NavLink>
            <span>&nbsp;that fit your location and unique needs.</span>
          </p>
          {/* <div className={`${styles.filterContainer} px-10 relative flex  flex-col items-center gap-6 py-4 bg-secondary-light/10`}> */}
          {/* <img className="object-contain w-full grayscale-[0.6] absolute bottom-0 opacity-10 -z-10" src={londonIllustration} alt="illustration of london" /> */}
          <div
            className={`${styles.filters} bg-white px-10 py-5 rounded-lg w-full`}
          >
            {/* <div className={`${styles.filters} bg-white px-10 py-5 rounded-lg w-full`}> */}
            <div className={`${styles.search} w-full flex items-center gap-2`}>
              <input
                className="input input-sm input-bordered w-full"
                type="text"
                placeholder="Search with a development name: e.g. Knightsbridge Gate"
                value={searchName}
                onChange={(e: any) => {
                  setSearchName(e.target.value);
                }}
              />
              <button
                className="btn btn-sm bg-main hover:bg-secondary-dark text-white"
                onClick={handleUpdateFilter}
              >
                Search
              </button>
              <NavLink to="/property?view=map">
                <button className="btn btn-sm whitespace-nowrap bg-gray-100 hover:bg-gray-200 text-gray-500 font-normal">
                  Advanced Search
                </button>
              </NavLink>
            </div>
            <div className={`${styles.price} flex gap-2 items-center`}>
              <p className="">{lang ? "Price:" : "价格"}</p>
              <label className={styles.inputWithCornerLabel}>
                <span>{lang ? "From" : "从"}</span>
                <input
                  className="input input-sm input-bordered  w-32"
                  id="outlined-price-from"
                  type="text"
                  value={
                    displayPriceRange && displayPriceRange[0] ? "£" + displayPriceRange[0].toLocaleString() : 0
                  }
                  onChange={(e) => {
                    updateFilterPrice(e, 0);
                  }}
                  placeholder={lang ? "From" : "从"}
                />
              </label>

              <RightArrowIcon width="24" height="24" />
              <label className={styles.inputWithCornerLabel}>
                <span>{lang ? "To" : "至"}</span>
                <input
                  className="input input-sm input-bordered  w-32"
                  id="outlined-price-to"
                  type="text"
                  value={
                    displayPriceRange && displayPriceRange[1] ? "£" + displayPriceRange[1].toLocaleString() : 0
                  }
                  onChange={(e) => {
                    updateFilterPrice(e, 1);
                  }}
                  placeholder={lang ? "To" : "至"}
                />
              </label>
            </div>
            <div className={`${styles.bedrooms} flex gap-2 items-center`}>
              <p className="">{lang ? "Bedrooms:" : "户型"}</p>
              {localFilters.bedrooms && Object.keys(localFilters.bedrooms).length > 0 && (
                <div className={`${styles.checkboxesContainer} `}>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.bedrooms["bed0"]}
                      name="bed0"
                      onChange={updateFilterBedrooms}
                    />
                    <span>{lang ? "Studio" : "开间"}</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.bedrooms["bed1"]}
                      name="bed1"
                      onChange={updateFilterBedrooms}
                    />
                    <span>{lang ? "1" : "一室"}</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.bedrooms["bed2"]}
                      name="bed2"
                      onChange={updateFilterBedrooms}
                    />
                    <span>{lang ? "2" : "两室"}</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.bedrooms["bed3"]}
                      name="bed3"
                      onChange={updateFilterBedrooms}
                    />
                    <span>{lang ? "3" : "三室"}</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.bedrooms["bed4"]}
                      name="bed4"
                      onChange={updateFilterBedrooms}
                    />
                    <span>{lang ? "4" : "四室"}</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={localFilters.bedrooms["bed5"]}
                      name="bed5"
                      onChange={updateFilterBedrooms}
                    />
                    <span className="text-nowrap">{lang ? "5+" : "五室"}</span>
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>

        {(
          // {filteredDevelopmentIds &&
          //   Object.keys(filteredDevelopmentIds).length > 0 && (
          <div className="w-full max-w-[1200px] p-10">
            <div
              className="home-page-container relative rounded-2xl border-4 border-white"
              style={{ boxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)" }}
            >
              {/* <TubeView zoomed={true} /> */}
              <MapView onHomePage={true} rounded={true} />
              {/* <img src={test} /> */}
            </div>
          </div>
        )}
        <div className="max-w-[500px] my-40 flex flex-col items-center relative z-10">
          <p className="font-poster text-4xl sm:text-6xl opacity-20 flex flex-col items-center gap-5">
            {lang ? (
              <>
                <span className="">Elegant Living, </span>
                <span className="">Confident Investing</span>
              </>
            ) : (
              <span className="font-poster-cn text-shadow-inner">
                优雅生活，自信投资
              </span>
            )}
          </p>
          <p className="pt-10 pb-20 text-center leading-loose text-lg text-secondary-dark">
            {lang ? (
              <p className="">
                <span className="sm:inline-flex sm:items-center sm:whitespace-nowrap">
                  Whether it is a&nbsp;
                  <span className="inline-flex mx-1 bg-tertiary-light/50 shadow-xl border-tertiary-light font-normal px-2 rounded-md gap-2 items-center">
                    <SofaIcon width="18" height="18" />
                    primary residence
                  </span>
                  ,
                  <span className="inline-flex mx-1 bg-tertiary-light/50 shadow-xl border-tertiary-light font-normal px-2 rounded-md gap-2 items-center">
                    <BeachIcon width="18" height="18" />
                    holiday home
                  </span>
                  ,&nbsp;or&nbsp;
                  <span className="inline-flex mx-1 bg-tertiary-light/50 shadow-xl border-tertiary-light font-normal px-2 rounded-md gap-2 items-center">
                    <InvestIcon width="18" height="18" />
                    investment
                  </span>
                  ,
                  {/* <span className="inline-flex mx-1 border-gray-200 border shadow-xl font-normal px-2 rounded-md gap-2 items-center"><SofaIcon width="18" height="18" />primary residence</span>,
                                    <span className="inline-flex mx-1 border-gray-200 border shadow-xl font-normal px-2 rounded-md gap-2 items-center"><BeachIcon width="18" height="18" />holiday home</span>,&nbsp;or&nbsp;
                                    <span className="inline-flex mx-1 border-gray-200 border shadow-xl font-normal px-2 rounded-md gap-2 items-center"><InvestIcon width="18" height="18" />investment</span>, */}
                </span>
                <span>
                  we are here to help you to find the right property in the
                  right location.
                </span>
              </p>
            ) : (
              <p>
                不论您是自住还是投资房产，我们都会尽专业所能，
                <br />
                用心帮助您和家人找到合适的项目和理想的地点。
              </p>
            )}
          </p>
          <div
            className={`flex ${lang ? "flex-col" : "flex-row"
              } xs:flex-row gap-2 tracking-wider`}
          >
            <NavLink
              className="font-normal btn px-6 bg-main hover:bg-secondary-dark text-white rounded-full"
              to="/property?view=list"
            >
              {lang ? "BROWSE PROPERTIES" : "浏览房产"}
            </NavLink>
            <HashLink
              className="font-medium  btn px-6 bg-transparent border border-main hover:bg-transparent text-secondary-dark rounded-full"
              to="/#contact-us"
            >
              {lang ? "CONTACT US" : "联系我们"}
            </HashLink>
          </div>
        </div>
        <img
          className="opacity-10 grayscale-[0.6] absolute bottom-6 right-0 w-screen"
          src={londonIllustration}
          alt="london illustartion art background"
        />
        {/* <img className="opacity-30 absolute bottom-[15%] right-[5%] w-[90%] object-cover" src={londonIllustration} alt="london illustartion art background" /> */}
      </section>
      <main className="">
        <div className="bg-gray-50/20 px-10 xs:px-20 sm:px-32 py-20 pt-40 flex flex-col gap-10">
          {/* <div className="bg-gray-100 px-32 py-20 flex flex-col gap-10"> */}
          <h2 className="text-secondary-dark">
            {lang ? "About NVRE" : "关于 NVRE"}
          </h2>
          <p className="leading-loose text-lg opacity-80">
            {lang
              ? `NVRE is a top-tier real estate business consulting firm based in central London, certified by the UK's foremost real estate regulator - "ARLA Propertymark". Our team comprises Cambridge University graduates with over 15 years of senior management and professional experience in real estate and asset management.`
              : `NVRE 是一家总部位于英国伦敦的高端地产咨询公司，受英国权威房产监管机构ARLA Propertymark认证，为高净值客户及家族企业提供专业高效的地产交易及租赁管理服务。我们的创始团队成员均毕业于剑桥大学，在英国的地产置业领域拥有多年的管理经验。专业性是广大客户选择我们的首要因素。`}
          </p>
          <NavLink
            className="button-learnmore text-lg opacity-80  text-secondary-light"
            to="/about"
          >
            {lang ? "Learn More" : "了解更多"}
          </NavLink>
        </div>
        <div className="px-10 xs:px-20 sm:px-32 py-20">
          <Services service={service} />
        </div>
        {/* <Insights /> */}
        <Partners />

        <div
          id="front-contact-us"
          className="px-10 xs:px-20 sm:px-32 pt-20 bg-tertiary-light/30"
          ref={targetRef}
        >
          <Contact />
        </div>
      </main>
    </div>
  );
}

function Services(props: any) {
  const { lang } = useContext(GlobalContext) as IGlobalContext;

  return (
    <section className="mb-20">
      <div className="relative">
        <h2 className="">{lang ? "One-Stop Shop Services" : "一站式"}</h2>
        <p className={`font-poster${lang ? "" : "-cn"} desc`}>
          {lang ? "Everything You Need" : "VIP 服务"}
        </p>
      </div>
      <div
        className={`grid ${lang ? "grid-cols-1" : "grid-cols-2"
          } sm:grid-cols-2 md:grid-cols-3 gap-12 sm:gap-6 mt-16`}
      >
        {props.service.map((item: any, index: any) => {
          return (
            <div
              className="flex gap-4 items-center"
              key={`service-${item}-${index}`}
            >
              <div className="p-3 bg-tertiary-light/60 rounded-xl">
                <item.img width="30" height="30" />
              </div>
              <p className="text-xl font-medium opacity-90">
                {lang ? item.en : item.zh}
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
}
