import React, { useState, useEffect, useContext } from "react";
import SalePage from "../pages/Sale/SalePage";
import HomePage from "../pages/HomePage/HomePage";
import Header from "../layouts/Header/Header";
import Footer from "../layouts/Footer/Footer";
import Rent from "../pages/Rent/Rent";
import About from "../pages/About/About";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
// import RegisterLoginPage from "../pages/RegisterLogin/RegisterLogin";
import { SalesContext } from "../context/SalesContext";
import StampDutyPage from "../pages/Services/StampDutyPage/StampDutyPage";
import RentalYieldPage from "../pages/Services/YieldPage/YieldPage";
import RentEstimatePage from "../pages/Services/RentEstimatePage/RentEstimatePage";
import MortgagePage from "../pages/Services/MorgagePage/MortgagePage";
import { GlobalContext } from "../context/GlobalContext";
import ToolMenu from "../items/ToolMenu/ToolMenu";
import Test from "../util/Test";
import ClientInfo from "../pages/Admin/Admin";
import ContactUs from "../items/ContactPopup/ContactPopup";
import PermissionPopup from "../items/PermissionPopup/PermissionPopup";
import ActionPopup from "../items/ActionPopup/ActionPopup";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const AppRoutes = () => {
  const { headerHeight, showContactPopup, permission, showPermissionPopup, menuBarHeight, showActionPopup, setShowActionPopup } = useContext(GlobalContext);
  return (
    <BrowserRouter>
      <Header />
      <ScrollToTop />
      <main
        className={`relative transition-all duration-500 ease-in-out z-[999]`}
        // className={`${headerHeight && "mt-[" + headerHeight + "px]"} relative transition-all duration-500 ease-in-out z-[999]`}
        style={{ minHeight: `calc(100vh - ${headerHeight}px)`, marginTop: headerHeight + "px" }}
      >
        <Routes>
          <Route path="/let" element={<Rent />} />
          <Route
            path="/services/stamp-duty-calculator"
            element={<StampDutyPage />}
          />
          <Route path="/services/rental-yield" element={<RentalYieldPage />} />
          <Route
            path="/services/rent-estimate"
            element={<RentEstimatePage />}
          />
          <Route
            path="/services/mortgage-calculator"
            element={<MortgagePage />}
          />
          <Route path="/about" element={<About />} />
          <Route path="/property" element={<SalePage />} />
          {/* <Route path="/login" element={<RegisterLoginPage />} /> */}
          {permission > 4 &&
            <Route path="/client-info" element={<ClientInfo />} />
          }
          <Route path="/" element={<HomePage />} />
        </Routes>
      </main>
      <div style={{ minHeight: "unset" }}>
        <ToolMenu />
        {showContactPopup && <ContactUs />}
        {showPermissionPopup && <PermissionPopup />}
        {showActionPopup && <ActionPopup />}
        <div className={`${(showContactPopup || showActionPopup) ? "fixed top-0 left-0 w-screen h-screen bg-black/20 z-[99999]" : ""}`}></div>
      </div>
      <Footer />
    </BrowserRouter>
  );
};
