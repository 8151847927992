import React, { useContext, useEffect, useRef, useState } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { Helmet } from "react-helmet";
import { generatePDFDocumentFromRef } from "../../../util/PDFRelated/generatePDF";
import { SalesContext } from "../../../context/SalesContext";
import { GlobalContext } from "../../../context/GlobalContext";
import { IGlobalContext } from "../../../types/types";
// import LanguageToggle from "../../../components/LanguageToggle/LanguageToggle";
import "./MortgagePage.scss";



export default function MortgagePage(props: any) {
    const [inputs, setInputs] = useState<any>({
        type: "interestOnly",
        price: null,
        deposit: null,
        term: 25,
        rate: 5.25
    });
    const [result, setResult] = useState<any>({ status: "null", data: null });
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("");
    const { isRealMobile, lang } = useContext(GlobalContext) as IGlobalContext;
    const tableRef = useRef(null);
    const language = new URLSearchParams(window.location.search).get('language');
    const [showBreakdown, setShowbreakdown] = useState(false);

    function handleEstimateRent(e: any) {
        e.preventDefault();
        setError("");
        setLoading(true);
        const { type, price, deposit, term, rate } = inputs;
        if (!(price && deposit && term && rate)) {
            setError(lang ? "Please complete all fields." : "请填写完整表格。");
            setLoading(false);
            return;
        }

        const result = calculateMortgage(type, price, deposit, term, rate);
        console.log(result);
        if (result) {
            setResult({
                status: "success",
                data: result
            });
            setLoading(false);
        }
    }

    function calculateMortgage(mortgageType: string, propertyPrice: number, deposit: number, termYears: number, annualInterestRate: number) {
        const loanAmount = propertyPrice - deposit;
        const monthlyInterestRate = annualInterestRate / 12 / 100;
        const totalPayments = termYears * 12;
        let monthlyPayment;
        let totalRepay = 0;
        let yearlyBreakdown = [];

        if (mortgageType === 'repayment') {
            // Calculate monthly payment for repayment mortgage
            monthlyPayment = (loanAmount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -totalPayments));
            for (let year = 1; year <= termYears; year++) {
                let remainingDebt = loanAmount;
                for (let month = 1; month <= year * 12; month++) {
                    let interestForThisMonth = remainingDebt * monthlyInterestRate;
                    let principalForThisMonth = monthlyPayment - interestForThisMonth;
                    remainingDebt -= principalForThisMonth;
                }
                yearlyBreakdown.push({ year: year, remainingDebt: remainingDebt });
            }
            totalRepay = monthlyPayment * totalPayments;
        } else if (mortgageType === 'interestOnly') {
            // Calculate monthly payment for interest-only mortgage
            monthlyPayment = loanAmount * monthlyInterestRate;
            for (let year = 1; year <= termYears; year++) {
                yearlyBreakdown.push({ year: year, remainingDebt: loanAmount });
            }
            totalRepay = monthlyPayment * totalPayments + (propertyPrice - deposit);
        } else {
            return "Invalid mortgage type";
        }

        return {
            monthlyPayment: monthlyPayment.toFixed(2),
            totalRepay: totalRepay.toFixed(2),
            yearlyBreakdown: yearlyBreakdown.map(item => ({ ...item, remainingDebt: item.remainingDebt.toFixed(2) }))
        };
    }





    function handleDownloadPDF() {
        setIsGeneratingPDF(true);
    }


    useEffect(() => {
        (async () => {

            if (isGeneratingPDF && tableRef.current) {
                try {

                    const doc = await generatePDFDocumentFromRef(tableRef.current);
                    if (doc) {
                        doc.save(`NVRE Mortgage Calculation.pdf`);
                        // const pdfBlob = doc.output('blob');
                        // const pdfUrl = URL.createObjectURL(pdfBlob);
                        // setPdfUrl(pdfUrl);

                        // !isMobile && window.open(pdfUrl, '_blank');

                        setIsGeneratingPDF(false);
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsGeneratingPDF(false);
                }

            }
        })();
    }, [isGeneratingPDF, tableRef.current]);



    return (
        <>
            <Helmet>
                <title>Mortgage Calculator | Letting Engine</title>
                <meta
                    name="description"
                    content="Use our mortgage calculator for your property."
                />
                <meta
                    name="keywords"
                    content="residential property, real estate, london, mortgage calculator"
                />
            </Helmet>
            {/* <LanguageToggle language={isEnglish} setLanguage={setIsEnglish} /> */}
            <div>
                {/* <div className={`flex flex-col items-center ${isEnglish ? " isEnglish" : "isChinese"}`}> */}

                <div ref={tableRef} id="to-print-mortgage" className={`content-inner ${result.status === "success" ? "items-start" : "justify-center"} flex gap-20 ${props.page === "tool" ? "" : "p-10 sm:p-16"}  ${props.page === "tool" ? "" : ` mx-auto`}`}>
                    <form
                        onSubmit={handleEstimateRent}
                        onChange={() => { setError(""); setResult({ status: "null" }); setPdfUrl(""); }}
                        className={`flex flex-col ${result.status === "success" ? "gap-2 sm:w-[300px]" : "gap-4 sm:w-[600px]"}  text-secondary-dark text-nowrap`}
                    >
                        <h1 className="mb-1 sm:mb-10 font-semibold text-4xl">{lang ? "Mortgage Calculator" : "贷款计算器"}</h1>

                        <h3 style={{ color: "rgb(255 198 0)" }}>Your Information</h3>
                        <div className={`label ${result.status === "success" ? null : "original"}`}>
                            <span className="">{lang ? "Type of mortgage:" : "贷款种类:"}</span>
                            <div className="flex gap-4 sm:max-w-xs sm:w-full">
                                <label className="flex items-center gap-1">
                                    <input
                                        className="radio radio-sm"
                                        type="radio"
                                        value="interestOnly"
                                        checked={inputs.type === "interestOnly"}
                                        onChange={() => setInputs({ ...inputs, type: "interestOnly" })}
                                    />
                                    <span>{lang ? "Interest-only" : "仅付利息"}</span>
                                </label>
                                <label className="flex items-center gap-1">
                                    <input
                                        className="radio radio-sm"
                                        type="radio"
                                        value="repayment"
                                        checked={inputs.type === "repayment"}
                                        onChange={() => setInputs({ ...inputs, type: "repayment" })}
                                    />
                                    <span>{lang ? "Repayment" : "本息还款"}</span>
                                </label>
                            </div>
                        </div>

                        <label className={`label ${result.status === "success" ? null : "original"} `}>
                            <span>{lang ? "Property price:" : "房产价格:"}</span>
                            <input
                                type="text"
                                value={inputs.price ? inputs.price.toLocaleString() : null}
                                placeholder="500,000"
                                onChange={(e) => {
                                    const value = e.target.value.replace(/,/g, '');
                                    setInputs({ ...inputs, price: value ? Number(value) : '' });
                                }}
                                style={{
                                    paddingLeft: "2rem"
                                }}
                            />
                            <span className={`left`}>£</span>
                        </label>

                        <label className={`label ${result.status === "success" ? null : "original"} `}>
                            <span>{lang ? "Deposit:" : "首付:"}</span>
                            <input
                                type="text"
                                value={inputs.deposit ? inputs.deposit.toLocaleString() : null}
                                placeholder="200,000"
                                onChange={(e) => {
                                    const value = e.target.value.replace(/,/g, '');
                                    setInputs({ ...inputs, deposit: value ? Number(value) : '' });
                                }}
                                style={{
                                    paddingLeft: "2rem"
                                }}
                            />
                            <span className={`left`}>£</span>
                        </label>

                        <label className={`label ${result.status === "success" ? null : "original"} `}>
                            <span >{lang ? "Mortgage term:" : "按揭期限:"}</span>
                            <input
                                type="number"
                                value={inputs.term}
                                step="1"
                                placeholder="25"
                                onChange={(e) => setInputs({ ...inputs, term: e.target.value })}
                            />
                            <span className={`right`}>Years</span>
                        </label>

                        <label className={`label ${result.status === "success" ? null : "original"} `}>
                            <span >{lang ? "Interest rate:" : "利率:"}</span>
                            <input
                                type="number"
                                value={inputs.rate}
                                placeholder="5.25"
                                step="0.01"
                                onChange={(e) => setInputs({ ...inputs, rate: e.target.value })}
                            />
                            <span className={`right`}>%</span>
                            {/* <span className={`top-[3.25rem] right-6`}>%</span> */}
                        </label>

                        <button className={`btn pt-0 ${props.page === "tool" ? "mt-10" : "mt-0"} ${result.status === "success" ? "hidden" : ""}`} type="submit">
                            {loading
                                ? <span>{lang ? "Calculating." : "为您计算中..."}</span>
                                : <span>{lang ? "Calculate" : "计算"}</span>}
                        </button>
                    </form>
                    <div id="mortgage-results" className="pt-32 flex flex-col">
                        {error && <p className="font-semibold mb-4 text-main">{error}</p>}
                        {result && result.status === "success" && result.data &&
                            <>
                                <h3 className="text-main mb-5">Results</h3>
                                <h3 className="font-semibold mb-4 text-main text-3xl">
                                    <span>{lang ? "Monthly payment: " : "月还款额： "}</span>
                                    <span>£{(+result.data.monthlyPayment).toLocaleString('en-US')}</span>
                                </h3>
                                <p className="text-xl">
                                    <span>{lang ? "Total repay: " : "总还款额："}</span>
                                    <span>£{(+result.data.totalRepay).toLocaleString()}</span>
                                </p>
                                <div className="opacity-60">
                                    <p>
                                        <span>- {lang ? "Capital: " : "本金："}</span>
                                        <span>£{(+inputs.price - +inputs.deposit).toLocaleString()}</span>
                                    </p>
                                    <p>
                                        <span>- {lang ? "Interest: " : "利息："}</span>
                                        <span>£{(+result.data.totalRepay - (+inputs.price - +inputs.deposit)).toLocaleString()}</span>
                                    </p>
                                </div>
                                <div className="whitespace-nowrap flex flex-col gap-10 items-center w-[400px] mt-10">
                                    <div className="flex w-full">
                                        <div style={{ width: `${(+inputs.price - +inputs.deposit) * 100 / +result.data.totalRepay}%` }}>
                                            <p className="text-center text-gray-700 text-base font-medium">
                                                <span className="opacity-70 font-normal text-sm">{lang ? "Capital: " : "本金："}</span>
                                                <span>£{(+inputs.price - +inputs.deposit).toLocaleString()}</span>
                                            </p>
                                            <div className={`special-line h-full bg-main`}></div>
                                        </div>
                                        <div style={{ width: `${(+result.data.totalRepay - (+inputs.price - +inputs.deposit)) * 100 / +result.data.totalRepay}%` }}>
                                            <p className="text-center text-gray-700 text-base font-medium">
                                                <span className="opacity-70 font-normal text-sm">{lang ? "Interest: " : "利息："}</span>
                                                <span>£{(+result.data.totalRepay - (+inputs.price - +inputs.deposit)).toLocaleString()}</span>
                                            </p>
                                            <div className={`special-line h-full bg-secondary-light`} ></div>
                                        </div>
                                    </div>
                                    <p className="-mt-4 mb-10 text-gray-700 text-base font-medium">
                                        <span className="opacity-70 font-normal text-sm">{lang ? "Total repay: " : "总还款额："}</span>
                                        <span>£{(+result.data.totalRepay).toLocaleString()}</span>
                                    </p>
                                </div>


                                <p id="mortgage-show-breakdown" className="hover:cursor-pointer flex items-center text-lg mt-12 mb-3 gap-2 text-main"
                                    onClick={() => { setShowbreakdown(!showBreakdown); }}>
                                    <span className="material-symbols-outlined">{showBreakdown ? "expand_less" : "expand_more"}</span>
                                    {showBreakdown ? (lang ? "Hide" : "隐藏") : (lang ? "Show" : "展开")}{lang ? " breakdown" : "明细"}
                                </p>
                                <div className='mb-10 opacity-50'>
                                    <p>{lang ? "The amounts in the table are for reference only and are subject to actual occurrence." : "表中的金额以实际发生为准，以上数据仅供参考。"}</p>
                                </div>
                                {showBreakdown &&

                                    <table className='table w-[400px] mortgage-result-table'>
                                        <thead className="relative">
                                            <tr >
                                                <th>{lang ? "Year" : "年份"}</th>
                                                <th>{lang ? "Remaining Debt" : "剩余债务"}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {result.data.yearlyBreakdown.map((item: any) => (
                                                <tr key={item.year} >
                                                    <td className="w-[300px]">{item.year}</td>
                                                    <td>£{(+item.remainingDebt).toLocaleString()}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                }


                            </>
                        }
                    </div>
                </div>
                {!error && result && result.status === "success" && result.data &&
                    <div className="mb-20 -mt-10 flex flex-col items-center ">
                        <button className={`btn btn-sm px-4 py-2 rounded-lg  self-center ${props.page === "tool" ? "mt-10" : "mt-0 mb-10 sm:mb-60"}`} onClick={handleDownloadPDF}>
                            {isGeneratingPDF ? <span>{lang ? "DOWNLOADING PDF..." : "PDF 下载中..."}</span> : (
                                <span>{lang ? "DOWNLOAD PDF" : "下载 PDF"}</span>
                            )}
                        </button>
                        {isRealMobile && pdfUrl && (
                            <div className="pdf-download-link">
                                <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                                    <span>{lang ? "Open PDF" : "打开 PDF"}</span>
                                </a>
                            </div>
                        )}
                    </div>
                }
            </div >
        </>

    );
}


;
