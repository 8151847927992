import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import logo from "../../assets/media/logo/company/nvreLogoPurple.png";
import footer from "../../assets/media/background/nvre-pdf-footer.png";

export async function generateMapPDF() {
    const pdfDoc = new jsPDF({
        orientation: 'l',
        unit: 'mm',
        format: 'a4',
        compress: true
    });

    const mapElement = document.getElementById("map-to-print");
    console.log(mapElement);
    if (!mapElement) {
        console.error("Map element not found.");
        return;
    }
    return html2canvas(mapElement, {
        scale: 2,
        useCORS: true,
        logging: true,
        onclone: (documentClone) => {
            const styleElements = [

                { selector: '.grid-container', styles: { "grid-template-columns": 'repeat(4, 1fr)' } },
                { selector: '.development_small_container', styles: { width: '40mm' } },
                { selector: '.development_small_container', styles: { height: '30mm' } },
                { selector: '.development_small_container img', styles: { height: '30mm' } },
                { selector: '.development_small_container .text-container', styles: { margin: '3mm' } },
                { selector: '.development_small_container h2', styles: { fontSize: '12px', transition: 'none' } },
                { selector: '.development_small_container p, .development_small_container span, .development_small_container li,  .development_small_container li b', styles: { "font-size": '6px' } },
            ];

            styleElements.forEach(({ selector, styles }) => {
                documentClone.querySelectorAll(selector).forEach(element => {
                    Object.keys(styles).forEach(property => {
                        element.style.setProperty(property.replace(/([A-Z])/g, '-$1').toLowerCase(), styles[property], 'important');
                    });
                });
            });
        }
    }).then((canvas) => {
        const imageData = canvas.toDataURL('image/png');
        // console.log(imageData); // Check the data URL again

        if (imageData.startsWith("data:image/png")) {
            const logoWidth = 55.9 / 1.7;
            const logoHeight = 12.9 / 1.7;
            const imgWidth = pdfDoc.internal.pageSize.getWidth();
            const imgHeight = canvas.height * imgWidth / canvas.width;
            // pdfDoc.addImage(logo, 'PNG', pdfDoc.internal.pageSize.getWidth() - logoWidth - 10, 10, logoWidth, logoHeight);
            pdfDoc.addImage(imageData, 'PNG', 0, logoHeight + 20, imgWidth, imgHeight);
            pdfDoc.save('map.pdf');
        } else {
            console.error("Canvas data URL is not valid.");
        }
    }).catch((error) => {
        console.error("Error generating canvas:", error);
    });


    // return html2canvas(mapElement, { scale: 1, useCORS: true, logging: true }).then((canvas) => {
    //         const imageData = canvas.toDataURL('image/png');
    //         console.log(imageData); // Check the data URL again

    //         if (imageData.startsWith("data:image/png")) {
    //             const logoWidth = 55.9 / 1.7;
    //             const logoHeight = 12.9 / 1.7;
    //             const imgWidth = pdfDoc.internal.pageSize.getWidth();
    //             const imgHeight = canvas.height * imgWidth / canvas.width;
    //             pdfDoc.addImage(logo, 'PNG', pdfDoc.internal.pageSize.getWidth() - logoWidth - 10, 10, logoWidth, logoHeight);
    //             pdfDoc.addImage(imageData, 'PNG', 0, logoHeight + 20, imgWidth, imgHeight);
    //             pdfDoc.save('map.pdf');
    //         } else {
    //             console.error("Canvas data URL is not valid.");
    //         }
    //     }).catch((error) => {
    //         console.error("Error generating canvas:", error);
    //     });
}


export async function generatePDFDocumentFromRef(elementRef) {
    if (!elementRef) return null;

    const pdfDoc = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
        compress: true
    });

    const pdfWidth = pdfDoc.internal.pageSize.getWidth();
    const pdfHeight = pdfDoc.internal.pageSize.getHeight();

    // const mortgageTable = document.querySelector(".mortgage-result-table");
    // let mortgageTableDisplayStyle = "";
    // if (mortgageTable) {
    //     mortgageTableDisplayStyle = mortgageTable.style.display; // Save the current display style
    //     mortgageTable.style.display = 'none'; // Hide the table for the first page rendering
    // }

    return html2canvas(elementRef, {
        scale: 4,
        onclone: (clonedDoc) => {
            // const clonedElement = clonedDoc.querySelectorAll('h3');
            const rentEstimate = clonedDoc.querySelector('#to-print-rent-estimate');
            const stampDuty = clonedDoc.querySelector('#to-print-stamp-duty');
            const rentalYield = clonedDoc.querySelector('#to-print-rental-yield');
            const mortgage = clonedDoc.querySelector('#to-print-mortgage');
            if (rentEstimate) {
                rentEstimate.style.setProperty("width", "700px", "important");
                rentEstimate.style.setProperty("max-width", "unset", "important");
                rentEstimate.style.setProperty("min-width", "unset", "important");
                rentEstimate.style.setProperty("padding", "0", "important");
                const propertyTypeList = rentEstimate.querySelector('#property-type-list');
                const specialH2 = rentEstimate.querySelectorAll("h2");
                const form = rentEstimate.querySelector("form");
                const h1 = rentEstimate.querySelector("h1");
                if (propertyTypeList) {
                    propertyTypeList.style.setProperty("flex-direction", "column", "important");
                    propertyTypeList.style.setProperty("gap", "0", "important");
                }
                if (specialH2.length > 0) {
                    specialH2.forEach(i => {
                        i.style.setProperty("display", "block", "important");
                    });
                }
                if (form) {
                    form.style.setProperty("gap", "0", "important");
                }
                if (h1) {
                    h1.style.setProperty("color", "rgb(59, 8, 107)", "important");
                    h1.style.setProperty("font-family", "Ubuntu Sans, serif", "important");
                    h1.style.setProperty("font-size", "1.875rem", "important");
                    h1.style.setProperty("line-height", "2.25rem", "important");
                    h1.style.setProperty("font-weight", "700", "important");
                }
            }
            if (stampDuty) {
                stampDuty.style.setProperty("width", "750px", "important");
            }
            if (rentalYield) {
                rentalYield.style.setProperty("width", "700px", "important");
                rentalYield.style.setProperty("gap", "24px", "important");

                const title = rentalYield.querySelector("#rental-yield-title");
                const h2s = rentalYield.querySelectorAll("h2");
                const yieldResultLabels = rentalYield.querySelectorAll("#yield-results label");

                if (title) {
                    title.style.setProperty("display", "block", "important");
                    title.style.setProperty("color", "rgb(59, 8, 107)", "important");
                    title.style.setProperty("font-family", "Ubuntu Sans, serif", "important");
                    title.style.setProperty("font-size", "1.875rem", "important");
                    title.style.setProperty("line-height", "2.25rem", "important");
                    title.style.setProperty("font-weight", "700", "important");
                }
                if (h2s && h2s.length > 0) {
                    h2s.forEach(i => {
                        i.style.setProperty("font-size", "1.5rem", "important");
                        i.style.setProperty("line-height", "2rem", "important");
                        i.style.setProperty("font-weight", "600", "important");
                        i.style.setProperty("color", "rgb(91, 15, 141)", "important");
                    });
                }
                if (yieldResultLabels && yieldResultLabels.length > 0) {
                    yieldResultLabels.forEach(i => {
                        i.style.setProperty("line-height", "0", "important");
                        i.style.setProperty("gap", "0", "important");
                    });
                }
            }
            if (mortgage) {
                mortgage.style.setProperty("width", "700px", "important");
                mortgage.style.setProperty("padding", "0", "important");
                mortgage.style.setProperty("flex-direction", "column", "important");
                mortgage.style.setProperty("gap", "10px", "important");

                const labels = mortgage.querySelectorAll(".label");
                const title = mortgage.querySelector("h1");
                const form = mortgage.querySelector("form");
                const lefts = mortgage.querySelectorAll(".left");
                const rights = mortgage.querySelectorAll(".right");
                const specialLines = mortgage.querySelectorAll(".special-line");
                const resultsContainer = mortgage.querySelector("#mortgage-results");
                const showBreakdown = mortgage.querySelector("#mortgage-show-breakdown");
                if (title) {
                    title.style.setProperty("display", "block", "important");
                    title.style.setProperty("color", "rgb(59, 8, 107)", "important");
                    title.style.setProperty("font-family", "Ubuntu Sans, serif", "important");
                    title.style.setProperty("font-size", "1.875rem", "important");
                    title.style.setProperty("line-height", "2.25rem", "important");
                    title.style.setProperty("font-weight", "700", "important");
                }
                if (labels && labels.length > 0) {
                    labels.forEach(i => {
                        i.classList.add("original");
                        i.style.setProperty("width", "500px", "important");
                    });
                }
                if (form) {
                    form.style.setProperty("gap", "0", "important");
                }
                if (lefts && lefts.length > 0) {
                    lefts.forEach(i => {
                        i.style.setProperty("top", "0.5rem", "important");
                    });
                }
                if (rights && rights.length > 0) {
                    rights.forEach(i => {
                        i.style.setProperty("top", "0.5rem", "important");
                        i.style.setProperty("right", "1rem", "important");
                    });
                }
                if (specialLines && specialLines.length > 0) {
                    specialLines.forEach(i => {
                        i.style.setProperty("margin-top", "0.5rem", "important");
                        // i.style.setProperty("transform", "translateY(0.5rem)", "important");
                    });
                }
                if (resultsContainer) {
                    resultsContainer.style.setProperty("padding-top", "50px", "important");
                }
                if (showBreakdown) {
                    showBreakdown.style.setProperty("display", "none", "important");
                }
                // if (inputs && inputs.length > 0) {
                //     inputs.forEach(i => {
                //         i.style.setProperty("width", "200px", "important");
                //     });
                // }

            }



            const flexRows = clonedDoc.querySelectorAll(".return-flex-row");
            if (flexRows && flexRows.length > 0) {
                flexRows.forEach(element => {
                    // element.style.setProperty('padding-bottom', '30px', 'important');
                    element.style.setProperty("flex-direction", "row", "important");
                });
            }

            const clonedMortgageTable = clonedDoc.querySelector(".mortgage-result-table");
            if (clonedMortgageTable) {
                clonedMortgageTable.style.display = 'none';
            }

            const tds = clonedDoc.querySelectorAll('td, th');
            const labels = clonedDoc.querySelectorAll('label>span');
            const textInputs = clonedDoc.querySelectorAll('input');
            const h3 = clonedDoc.querySelectorAll('h3');
            const buttons = clonedDoc.querySelectorAll('button');
            const poundSigns = clonedDoc.querySelectorAll('.adj');

            if (tds && tds.length > 0) {
                tds.forEach(element => {
                    // element.style.setProperty('padding-bottom', '30px', 'important');
                    element.style.transform = "translateY(-7.5px)";
                });

            }

            if (labels && labels.length > 0) {
                labels.forEach(element => {
                    element.style.setProperty('padding-bottom', '15px', 'important');
                });
            }

            if (textInputs && textInputs.length > 0) {
                textInputs.forEach(element => {
                    element.style.setProperty("height", '37.5px', "important");
                    element.style.setProperty("padding-bottom", '7.5px', "important");
                });
            }

            if (poundSigns && poundSigns.length > 0) {
                poundSigns.forEach(element => {
                    element.style.setProperty("height", '37.5px', "important");
                    element.style.setProperty("padding-bottom", '7.5px', "important");
                });
            }

            if (h3 && h3.length > 0) {
                h3.forEach(element => {
                    element.style.setProperty("padding-bottom", '20px', "important");
                });
            }
            if (buttons && buttons.length > 0) {
                buttons.forEach(element => {
                    element.style.setProperty("display", 'none', "important");
                });
            }

            // const disclaimer1 = isEnglish ? "The amounts in the table are for reference only and are subject to actual occurrence;" : "表中的金额以实际发生为准，以上数据仅供参考；";
            // const disclaimer2 = isEnglish ? "If you reside continuously for 183 days after coming to the UK, you may be eligible for a 2% tax refund for overseas buyers with a visa that allows continuous residence." : "来英后连续居住183天可以退2%海外买家税，但需要购房者有可以连续居住的签证。";

            // const disclaimerParagraph1 = document.createElement('p');
            // disclaimerParagraph1.textContent = disclaimer1;
            // clonedDoc.body.appendChild(disclaimerParagraph1);

            // const disclaimerParagraph2 = document.createElement('p');
            // disclaimerParagraph2.textContent = disclaimer2;
            // clonedDoc.body.appendChild(disclaimerParagraph2);
        }
    }).then(async (canvas) => {
        const logoWidth = 55.9 / 1.7;
        const logoHeight = 12.9 / 1.7;
        pdfDoc.addImage(logo, 'PNG', pdfWidth - logoWidth - 10, 10, logoWidth, logoHeight);
        await addFooter(pdfDoc, pdfWidth, pdfHeight);



        const canvasAspectRatio = canvas.height / canvas.width;
        const pdfAspectRatio = pdfHeight / pdfWidth;
        let canvasHeight, canvasWidth;

        // if (canvasAspectRatio > 1) {
        //     canvasHeight = pdfHeight * 0.85;
        //     canvasWidth = canvasHeight / canvasAspectRatio;
        //     if (canvasWidth > pdfWidth) {
        //         canvasWidth = pdfWidth * 0.85;
        //         canvasHeight = canvasWidth * canvasAspectRatio;
        //     }
        // } else {
        //     canvasWidth = pdfWidth * 0.85;
        //     canvasHeight = canvasWidth * canvasAspectRatio;
        //     if (canvasHeight > pdfHeight) {
        //         canvasHeight = pdfHeight * 0.85;
        //         canvasWidth = canvasHeight / canvasAspectRatio;
        //     }
        // }

        const fixedCanvasWidth = 160; // Set your fixed canvas width here
        canvasHeight = fixedCanvasWidth * canvasAspectRatio;

        // Ensure the canvas height does not exceed the PDF height
        if (canvasHeight > pdfHeight) {
            canvasHeight = pdfHeight * 0.85;
            canvasWidth = canvasHeight / canvasAspectRatio;
        } else {
            canvasWidth = fixedCanvasWidth;
        }

        let marginLeft = 20;
        // let marginLeft = (pdfWidth - canvasWidth) / 2;

        // Add the logo at the top right
        const imageData = canvas.toDataURL('image/png');
        pdfDoc.addImage(imageData, 'PNG', marginLeft, logoHeight + 25, canvasWidth, canvasHeight); // Adjust the Y position to account for the logo

        const mortgageTable = document.querySelector(".mortgage-result-table");

        if (mortgageTable) {
            // Add a new page for the mortgage result table
            pdfDoc.addPage();

            // Ensure the table is visible for rendering
            mortgageTable.style.display = '';

            // Render the mortgage result table to canvas
            return html2canvas(mortgageTable, { scale: 4 }).then(async (mortgageCanvas) => {
                // ... calculations for the mortgage table on the second page ...

                // Add the mortgage table canvas to the second page
                pdfDoc.addImage(mortgageCanvas.toDataURL('image/png'), 'PNG', marginLeft, logoHeight + 15, canvasHeight * mortgageCanvas.width / mortgageCanvas.height, canvasHeight);
                // pdfDoc.addImage(mortgageCanvas.toDataURL('image/png'), 'PNG', marginLeft, logoHeight + 15, canvasWidth, canvasHeight);

                // Add the logo and footer to the second page
                pdfDoc.addImage(logo, 'PNG', pdfWidth - logoWidth - 10, 10, logoWidth, logoHeight);
                await addFooter(pdfDoc, pdfWidth, pdfHeight);

                return pdfDoc;
            });
        }



        // // Add the footer text at the bottom center
        // const footerText = "16 Berkeley Street, Mayfair, London, W1J 8DZ, United Kingdom      Tel: +44(0)20 3907 4024      www.nvre.co.uk";
        // const fontSize = 10; // Adjust the font s·ize as needed
        // pdfDoc.setFontSize(fontSize);
        // const textWidth = pdfDoc.getStringUnitWidth(footerText) * fontSize / pdfDoc.internal.scaleFactor;
        // const textX = (pdfWidth - textWidth) / 2; // Center the text
        // const textY = pdfHeight - 8; // Position the text 10mm from the bottom
        // pdfDoc.text(footerText, textX, textY);
        return pdfDoc;
    });

    // return html2canvas(elementRef, { scale: 4 }).then((canvas) => {
    //     // return html2canvas(elementRef, { scale: 4, windowHeight: document.body.scrollHeight }).then((canvas) => {
    //     const imageData = canvas.toDataURL();
    //     const imgWidth = pdfDoc.internal.pageSize.getWidth();
    //     const imgHeight = canvas.height * imgWidth / canvas.width;

    //     pdfDoc.addImage(imageData, 'PNG', 0, 0, imgWidth, imgHeight, undefined, "FAST");
    //     return pdfDoc;
    // });
}


async function addFooter(doc, docWidth, docHeight) {
    return new Promise((resolve, reject) => {
        const footerImage = new Image();
        footerImage.src = footer;
        footerImage.onload = () => {
            const footerImgWidth = docWidth;
            const footerImgHeight = (footerImage.height / footerImage.width) * footerImgWidth;
            doc.addImage(footerImage, 'PNG', 0, docHeight - footerImgHeight, footerImgWidth, footerImgHeight);
            resolve();
        };
        footerImage.onerror = reject;
    });
}