import { useContext } from "react";
import Contact from "../../pages/Contact/Contact";
import { GlobalContext } from "../../context/GlobalContext";
import { IGlobalContext } from "../../types/types";

export default function ContactUs() {
    const { setShowContactPopup } = useContext(GlobalContext) as IGlobalContext;
    return (
        <div className="fixed top-[50vh] left-[50vw] -translate-x-[50%] -translate-y-[50%] bg-white p-8 rounded-lg shadow-2xl border border-gray-500/10 shadow-gray-500/5 z-[9999999999999999999]">
            <p onClick={() => { setShowContactPopup(false); }} className="absolute top-6 right-6 text-secondary-light/50 hover:cursor-pointer">✕</p>
            <Contact page="popup" />
        </div>
    );
}