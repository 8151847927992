import { useEffect } from "react";
import { getAllClientInfo } from "../../api/user";
import { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

interface ClientInfo {
  id: number;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  mobile: string;
  wechat: string;
  date: Date;
}

export default function ClientInfo() {
  const [rowData, setRowData] = useState([]);
  const [columnDefs] = useState<any>([
    { headerName: "ID", field: "id", sortable: true, filter: true },
    {
      headerName: "First Name",
      field: "firstName",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Last Name",
      field: "lastName",
      sortable: true,
      filter: true,
    },
    { headerName: "Title", field: "title", sortable: true, filter: true },
    { headerName: "Email", field: "email", sortable: true, filter: true },
    { headerName: "Mobile", field: "mobile", sortable: true, filter: true },
    { headerName: "Wechat", field: "wechat", sortable: true, filter: true },
    {
      headerName: "Date",
      field: "date",
      sortable: true,
      filter: true,
      valueFormatter: (params: any) =>
        params.value ? new Date(params.value).toLocaleDateString() : "",
    },
  ]);

  useEffect(() => {
    (async () => {
      const data = await getAllClientInfo();
      setRowData(data);
    })();
  }, []);

  return (
    <div className="p-10">
      <p className="text-secondary-dark font-bold text-xl mb-3">
        Collected Potential Client Information
      </p>
      <div
        className="ag-theme-alpine"
        style={{ height: "70vh", width: "100%" }}
      >
        <AgGridReact<ClientInfo> rowData={rowData} columnDefs={columnDefs} />
      </div>
    </div>
  );
}
