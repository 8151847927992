import React, { useContext, useEffect } from "react";
import styles from "./ListView.module.css";
import ListBlock from "../Components/List/ListBlock";
import { useSelector } from "react-redux";
import { getAllDevelopments, getFilteredDevelopmentIds, getKeywordFilteredDevelopmentIds, getSortedDevelopmentIds } from "../../../features/developments/developmentsSlice";
import { SalesContext } from "../../../context/SalesContext";
import { IGlobalContext, ISalesContext } from "../../../types/types";
import { IncludeHouseIcon } from "../../../assets/assetExport";
import { GlobalContext } from "../../../context/GlobalContext";


function importAll(r: any) {
  let images: any = {};
  r.keys().map((item: any) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context(
    "../../../assets/media/developmentImages",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

export default function ListView(props: any) {
  const sortedDevelopmentIds = useSelector(getSortedDevelopmentIds);
  const allDevelopments = useSelector(getAllDevelopments);
  const { showFilter, setSort } = useContext(SalesContext) as ISalesContext;

  useEffect(() => {
    setSort("name");
  }, []);


  return (
    <>
      <div className={`${styles.gridContainer} ${showFilter && styles.withFilter}`}
        id="print-list-view">
        {sortedDevelopmentIds && sortedDevelopmentIds.length > 0 && sortedDevelopmentIds.map((id: any) => {
          const obj = allDevelopments[id];
          const imageId = String(obj.id).padStart(4, '0');
          const imageUrl = images[`NV${imageId.slice(-4)}.jpg`];

          return (
            <ListBlock
              key={obj["id"]}
              obj={{ ...obj, url: imageUrl }}
              renderWithSort={props.renderWithSort}
              sort={props.sort}
            />
          );
        })}
      </div>
      <p className="including-houses" style={{ marginLeft: "60px", marginBottom: "40px", fontSize: "20px", display: "flex", alignItems: "center" }}>  <IncludeHouseIcon width="18" height="18" />&nbsp; Including houses</p>
    </>
  );
}
