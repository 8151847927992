import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { persistor, store } from "./app/store";
import './index.css';
// import './print.css';
import { App } from './app/App';
import { GlobalContainer } from './context/GlobalContext';
import { PersistGate } from 'redux-persist/integration/react';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GlobalContainer />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
// root.render(
//   <Provider store={store} >
//     <GlobalContainer />
//   </Provider>
// );

