import React, { useContext, useState } from "react";
import { QuestionIcon } from "../../assets/assetExport";
import { GlobalContext } from "../../context/GlobalContext";
import { IGlobalContext } from "../../types/types";

export default function Help({ playTutorial }: { playTutorial: any; }) {
    const [open, setOpen] = useState(false);
    const { setShowContactPopup } = useContext(GlobalContext) as IGlobalContext;

    return (
        <div className="fixed bottom-5 left-5 z-[999999999999] p-2 rounded-full bg-fourth-light shadow-md border border-secondary-dark/10"
            onClick={() => { setOpen(true); }}
            onMouseLeave={() => { setOpen(false); }}
        >
            {open
                ?
                <div className="flex gap-6 font-medium text-main px-2">
                    <button onClick={playTutorial}>Tutorial</button>
                    <button onClick={() => { setShowContactPopup(true); }}>Contact Us</button>
                </div>
                :
                <div className="hover:cursor-pointer">
                    <QuestionIcon width="22" height="22" />
                </div>
            }
        </div>
    );
}