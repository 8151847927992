import React, {
  useState,
  useEffect,
  createContext,
  ReactNode,
  useContext,
} from "react";
import { IGlobalContext, ISalesContext } from "../types/types";
import { useDispatch, useSelector } from "react-redux";
import { GlobalContext } from "./GlobalContext";
import { AppDispatch } from "../app/store";
import { getAllDevelopments, getFilteredDevelopmentIds, getSortBy, setSort } from "../features/developments/developmentsSlice";
import { getPdfStatus } from "../features/states/statesSlice";

export const SalesContext = createContext<ISalesContext | null>(null);

export const SalesContainer: React.FC<{ children: ReactNode; }> = ({
  children,
}) => {
  const [isWidthHideFilter, setIsWidthHideFilter] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const [editMode, setEditMode] = useState<boolean>(false);
  // const [pdfStatus, setPdfStatus] = useState("hide"); // hide
  // const [sort, setSort] = useState("");

  const { isRealMobile } = useContext(GlobalContext) as IGlobalContext;
  const dispatch = useDispatch<AppDispatch>();
  // const filteredDevelopments = useSelector(getFilteredDevelopments);
  // const allDevelopments = useSelector(getAllDevelopments);
  const sort = useSelector(getSortBy);
  const pdfStatus = useSelector(getPdfStatus);

  function renderWithSort(value: any) {
    switch (value) {
      case "highest-price":
        dispatch(setSort({ attribute: "price", order: "desc" }));
        break;
      case "lowest-price":
        dispatch(setSort({ attribute: "price", order: "asc" }));
        break;
      case "zone":
        dispatch(setSort({ attribute: "zone", order: "asc" }));
        break;
      case "name":
        dispatch(setSort({ attribute: "name", order: "asc" }));
        break;
      default:
        console.error(`error`);
    }
  }

  useEffect(() => {
    const html = document.querySelector("html");
    const header = document.querySelector("header");
    if (header) {
      if (pdfStatus !== "hide") {
        if (html) html.style.overflow = "hidden";
        header.style.filter = "brightness(0.8)";
      } else {
        if (html) html.style.overflow = "unset";
        header.style.filter = "unset";
      }
    }
  }, [pdfStatus]);

  useEffect(() => {
    if (!isRealMobile) return;
    const html = document.querySelector("html");
    if (showFilter) {
      if (html) html.style.overflow = "hidden";
    } else {
      if (html) html.style.overflow = "unset";
    }
  }, [showFilter]);

  useEffect(() => {
    setIsWidthHideFilter(window.innerWidth < 920);
  }, []);

  useEffect(() => {
    let resizeTimer: ReturnType<typeof setTimeout>;

    const handleResize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        setIsWidthHideFilter(window.innerWidth < 920);
      }, 300);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (resizeTimer) clearTimeout(resizeTimer);
    };
  }, []);

  return (
    <SalesContext.Provider
      value={{
        isWidthHideFilter,
        setIsWidthHideFilter,
        showFilter,
        setShowFilter,
        editMode,
        setEditMode,
        sort, setSort,
        renderWithSort
      }}
    >
      {children}
    </SalesContext.Provider>
  );
};
